import { gsap } from "gsap";
import { displaySelect, onWhiteBackground, RegionMapping } from "..";
import { makeAsideSectionActive } from "../menu";
import $ from "jquery";
import {
  forwardDrawLine,
  reverseDrawLine,
  showPointTimeline,
  showSmallPointTimeline,
  WAViewBox,
} from "./why-invest";

const getLeftover = () => {
  const mapBounds = (
    document.querySelector("#why-invest-mobile-2-animation .map-image") as any
  ).getBoundingClientRect();
  const percentage = (mapBounds.width / window.innerWidth) * 100;
  const offset = 5;
  const leftOver = 100 - percentage + offset;
  return leftOver / 100;
};
export async function whyInvestTimelineMobile() {
  return new Promise<void>((resolve) => {
    let whyInvestTimelineMobile_1 = gsap.timeline({
      defaults: {
        duration: 3,
      },
      scrollTrigger: {
        trigger: "#why-invest-1",
        start: "top top",
        pin: true,
        end: "100%",
        pinSpacing: true,
        scrub: true,
        invalidateOnRefresh: true,
        // snap: {
        //   snapTo: "labelsDirectional",
        //   delay: 1,
        // },
        onLeaveBack: () => {
          makeAsideSectionActive();
        },
        onEnter: () => {
          makeAsideSectionActive("why-invest-1");
        },
        onEnterBack: () => {
          makeAsideSectionActive("why-invest-1");
        },
      },
    });

    //update urls
    Object.keys(RegionMapping).forEach((key) => {
      const region = RegionMapping[key];
      $(`#why-invest-1 #Map-WA-Locations #${key} a`).attr(
        "href",
        `${region.link}`
      );
    });
		
		$(".why-invest-container").addClass("background-gold")
    whyInvestTimelineMobile_1
      .set("#why-invest-1 .map-image", { opacity: 1 })
			.set("#why-invest-1 .map-image", { scale: 0.8 })
			.set("#why-invest-1 .map-image", { xPercent: 15 })
      .set("#why-invest-1 .content-container", { opacity: 0, pointerEvents: "none" })
      .set("#why-invest-1 #Map-GMT-Lines", { opacity: 0 })
      .set("#why-invest-1 .carts,#why-invest .hat,#why-invest .buildings", {
        opacity: 0,
      })
      .set("#why-invest-1 .map", { css: { zIndex: 2 } })
      .set("#why-invest-1 .map-image", { attr: { viewBox: WAViewBox } })
      .fromTo(
        "#why-invest .map-image #Map-WA-Regions",
        { opacity: 0 },
        { opacity: 1, duration: 3 },
        "<"
      )
      //points
      .add(showPointTimeline("browse", 0))
      .addLabel("browse-end")
      .add(showPointTimeline("boodarie", 4, true), "<")
      .addLabel("boodarie-end")
      .add(showPointTimeline("anketell", 5, true), "<")
      .addLabel("anketell-end")
      .add(showPointTimeline("burrup", 2), "<")
      .addLabel("burrup-end")
      .add(showPointTimeline("mitland", 6), "<")
      .addLabel("mitland-end")
      .add(showPointTimeline("ashburton", 3), "<")
      .addLabel("ashburton-end")
      .add(showPointTimeline("oakajee", 12), "<")
      .addLabel("oakajee-end")
      .add(showPointTimeline("kwinana", 7), "<")
      .addLabel("kwinana-end")
      .add(showPointTimeline("rockingham", 13), "<")
      .addLabel("rockingham-end")
      .add(showPointTimeline("kemberton", 8), "<")
      .addLabel("kemberton-end")
      .add(showPointTimeline("shotts", 11, true), "<")
      .addLabel("shotts-end")
      .add(showPointTimeline("mirambeena", 9), "<")
      .addLabel("mirambeena-end")
      .add(showPointTimeline("mungari", 10, true), "<")
      .addLabel("mungari-end");

			resolve()
  });
}

export async function whyInvestTimelineMobile2() {
  return new Promise<void>((resolve) => {
   
    const duration = 3;

    let whyInvestTimelineMobile_2 = gsap.timeline({
      defaults: {
        duration: duration,
      },
      scrollTrigger: {
        trigger: "#why-invest-mobile-2-animation",
        start: "top top",
        pin: true,
        end: "300%",
        pinSpacing: true,
        scrub: true,
        invalidateOnRefresh: true,
        onEnter: () => {
          makeAsideSectionActive("why-invest-2");
        },
        onEnterBack: () => {
          makeAsideSectionActive("why-invest-2");
        },
      },
    });
    whyInvestTimelineMobile_2
      .set("#why-invest-mobile-2-animation .map-image", {
        attr: { viewBox: WAViewBox },
      })
      .set("#why-invest-mobile-2-animation #Map-WA-Locations", { opacity: 0 })
			.set(
				"#why-invest-mobile-2-animation #Map-WA-invert",
				{ opacity:  0},
			)
      .to("#why-invest-mobile-2-animation .map-image", {
        attr: { viewBox: "0 0 1230 1024", duration: 5 },
      })
      .to(
        "#why-invest-mobile-2-animation .map-image #Map-WA-Regions",
        { opacity: 0 },
        "<"
      )
      .fromTo(
        "#why-invest-mobile-2-animation .map-image #Map-Global-Grey",
        { opacity: 0 },
        { opacity: 1 },
        "<"
      )
      // .fromTo("#why-invest-mobile-2-animation .map-image #Map-GMT-Lines", {opacity: 0},{ opacity: 1})
      .fromTo(
        "#why-invest-mobile-2-animation .map-image #Map-GMT-Lines #gmt-line-1",
        forwardDrawLine[0],
        forwardDrawLine[1]
      )
      .fromTo(
        "#why-invest-mobile-2-animation .map-image #Map-GMT-Lines #gmt-line-1-2",
        reverseDrawLine[0],
        reverseDrawLine[1],
        "<"
      )
      .fromTo(
        "#why-invest-mobile-2-animation .map-image #Map-GMT-Lines #gmt-line-2",
        reverseDrawLine[0],
        reverseDrawLine[1],
        "<"
      )
      .fromTo(
        "#why-invest-mobile-2-animation .map-image #Map-GMT-Lines #gmt-line-3",
        forwardDrawLine[0],
        forwardDrawLine[1],
        "<"
      )
      .fromTo(
        "#why-invest-mobile-2-animation .map-image #Map-GMT-Lines #gmt-line-4",
        reverseDrawLine[0],
        reverseDrawLine[1],
        "<"
      )
      .fromTo(
        "#why-invest-mobile-2-animation .map-image #Map-GMT-Lines #gmt-line-5",
        forwardDrawLine[0],
        forwardDrawLine[1],
        "<"
      )
      .fromTo(
        "#why-invest-mobile-2-animation .map-image #Map-GMT-Lines #gmt-line-6",
        forwardDrawLine[0],
        forwardDrawLine[1],
        "<"
      )
      .fromTo(
        "#why-invest-mobile-2-animation .map-image #Map-GMT-Lines #gmt-line-7",
        reverseDrawLine[0],
        reverseDrawLine[1],
        "<"
      )
      .fromTo(
        "#why-invest-mobile-2-animation .map-image #Map-GMT-Lines #gmt-line-8",
        forwardDrawLine[0],
        forwardDrawLine[1],
        "<"
      )
      .fromTo(
        "#why-invest-mobile-2-animation .map-image #MAP-GMT-Times",
        { opacity: 0 },
        { opacity: 1 },
        "<"
      )

      // .to("#why-invest", {duration: 3},)

      .fromTo(
        "#why-invest-mobile-2-animation .map-image #Map-Global-Orange",
        { opacity: 0 },
        { opacity: 1 }
      )
      .fromTo(
        "#why-invest-mobile-2-animation .map-image #MAP-GMT-Times #gmt-8",
        { opacity: 0 },
        { opacity: 1 },
        "<"
      )
      .addLabel("orange-shown")
      .fromTo(
        "#why-invest-mobile-2-animation .map-image #Map-Global-Yellow",
        { opacity: 0 },
        { opacity: 1 }
      )
      .fromTo(
        "#why-invest-mobile-2-animation .map-image #MAP-GMT-Times #gmt-7",
        { opacity: 0 },
        { opacity: 1 },
        "<"
      )
      .addLabel("yellow-shown")

      //shipping lines
      .fromTo(
        "#why-invest-mobile-2-animation .map-image #Map-WA-Shipping-lines ellipse",
        { opacity: 0 },
        { opacity: 1 }
      )
      .fromTo(
        "#why-invest-mobile-2-animation .map-image #Map-WA-10days",
        { opacity: 0 },
        { opacity: 1 }
      )
      .fromTo(
        "#why-invest-mobile-2-animation .map-image #Map-WA-Shipping-lines circle",
        { opacity: 0 },
        { opacity: 1, duration: 0.25 }
      )
      .fromTo(
        "#why-invest-mobile-2-animation .map-image #Map-WA-5days",
        { opacity: 0 },
        { opacity: 1 },
        ">-0.5"
      )
      .addLabel("world-map-shipping-shown")

      //points

      .add(showSmallPointTimeline("#why-invest-mobile-2-animation", "tokyo"))
      .add(
        showSmallPointTimeline("#why-invest-mobile-2-animation", "seoul"),
        `>-${duration * 2}`
      )
      .add(
        showSmallPointTimeline("#why-invest-mobile-2-animation", "hongkong"),
        `>-${duration * 2}`
      )
      .add(
        showSmallPointTimeline("#why-invest-mobile-2-animation", "beijing"),
        `>-${duration * 2}`
      )
      .add(
        showSmallPointTimeline("#why-invest-mobile-2-animation", "shanghai"),
        `>-${duration * 2}`
      )
      .add(
        showSmallPointTimeline("#why-invest-mobile-2-animation", "shenzhen"),
        `>-${duration * 2}`
      )
      .add(
        showSmallPointTimeline("#why-invest-mobile-2-animation", "dubai"),
        `>-${duration * 2}`
      )
      .add(
        showSmallPointTimeline("#why-invest-mobile-2-animation", "singapore"),
        `>-${duration * 2}`
      )
      .addLabel("small-pin-singapore-shown");

    let whyInvestTimelineMobile_3 = gsap.timeline({
      defaults: {
        duration: duration,
      },
      scrollTrigger: {
        trigger: "#why-invest-mobile-3-animation",
        start: "top top",
        pin: true,
        end: "200%",
        pinSpacing: true,
        scrub: true,
        invalidateOnRefresh: true,
      },
    });

    whyInvestTimelineMobile_3
      .from(
        "#why-invest-mobile-3-animation .carts #cart-right",
        { translateX: "-50%", translateY: "5%" },
        "<"
      )
      .fromTo(
        "#why-invest-mobile-3-animation .carts #car-road line",
        { drawSVG: "0" },
        { drawSVG: "100%" },
        "<"
      )
      .fromTo(
        "#why-invest-mobile-3-animation .carts #cart-right-trolley",
        { drawSVG: "0" },
        { drawSVG: "100%" },
        ">"
      )
      .fromTo(
        "#why-invest-mobile-3-animation .carts #cart-right-wheel-right",
        { opacity: "0" },
        { opacity: "100%" },
        ">"
      )
      .fromTo(
        "#why-invest-mobile-3-animation .carts #cart-right-wheel-left",
        { opacity: "0" },
        { opacity: "100%" },
        ">"
      )
      .fromTo(
        "#why-invest-mobile-3-animation .carts #cart-right-dirt",
        { opacity: "0" },
        { opacity: "100%" },
        ">"
      )
      .fromTo(
        "#why-invest-mobile-3-animation .carts #cart-chain",
        { opacity: "0" },
        { opacity: "100%" },
        ">"
      )
      .addLabel("cart-shown")

      .to("#why-invest-mobile-3-animation .carts #cart-right", {
        translateX: "150%",
        ease: "none",
        duration: duration * 2,
      })
      .fromTo(
        "#why-invest-mobile-3-animation .carts #car-road line",
        { drawSVG: "0% 100%" },
        { drawSVG: "100% 100%" },
        ">"
      );

    let whyInvestTimelineMobile_4 = gsap.timeline({
      defaults: {
        duration: duration,
      },
      scrollTrigger: {
        trigger: "#why-invest-mobile-4-animation",
        start: "top top",
        pin: true,
        end: "200%",
        pinSpacing: true,
        scrub: true,
        invalidateOnRefresh: true,
      },
    });

    whyInvestTimelineMobile_4
			.set("#why-invest-mobile-4-animation .hat #doughnut-fill path", { drawSVG: "0" })
      .fromTo(
        "#why-invest-mobile-4-animation .hat #doughnut-outline .cls-3:nth-child(1)",
        { drawSVG: "0" },
        { drawSVG: "100%", duration: duration * 3 }
      )
      .fromTo(
        "#why-invest-mobile-4-animation .hat #doughnut-outline .cls-3:nth-child(2)",
        { drawSVG: "0" },
        { drawSVG: "-100%", duration: duration * 3 },
        "<"
      )
      .fromTo(
        "#why-invest-mobile-4-animation .hat #doughnut-hard-hat path",
        { drawSVG: "0" },
        { drawSVG: "100%", duration: duration * 3 },
        `<`
      )
      .fromTo(
        "#why-invest-mobile-4-animation .hat #doughnut-shape",
        { opacity: 0 },
        { opacity: 1 }
      )
      .to("#why-invest-mobile-4-animation .hat #doughnut-fill path", {
        drawSVG: "25%",
        duration: duration * 2,
        ease: "none",
      })
      .addLabel("hat-start")
      .to("#why-invest-mobile-4-animation .hat #doughnut-fill path", {
        drawSVG: "100%",
        duration: duration * 2,
        ease: "none",
      })
      .addLabel("hat-done")
      .to("#why-invest-mobile-4-animation .hat", {})


    let whyInvestTimelineMobile_5 = gsap.timeline({
      defaults: {
        duration: duration,
      },
      scrollTrigger: {
        trigger: "#why-invest-mobile-5-animation",
        start: "top top",
        pin: true,
        end: "200%",
        pinSpacing: true,
        scrub: true,
        invalidateOnRefresh: true,
      },
    });

    whyInvestTimelineMobile_5
      .fromTo(
        "#why-invest-mobile-5-animation .buildings #factory-1",
        { translateY: "100%" },
        { translateY: "0%" }
      )
      .addLabel("buildings-start-1")
      .fromTo(
        "#why-invest-mobile-5-animation .buildings #factory-2",
        { translateY: "100%" },
        { translateY: "0%" },
        ">-0.1"
      )
      .addLabel("buildings-start-2")
      .fromTo(
        "#why-invest-mobile-5-animation .buildings #factory-3",
        { translateY: "100%" },
        { translateY: "0%" },
        ">-0.1"
      )
      .addLabel("buildings-start-3")
      .fromTo(
        "#why-invest-mobile-5-animation .buildings #factory-4",
        { translateY: "100%" },
        { translateY: "0%" },
        ">-0.1"
      )
      .addLabel("buildings-start-4")
      .fromTo(
        "#why-invest-mobile-5-animation .buildings #factory-5",
        { translateY: "100%" },
        { translateY: "0%" },
        ">-0.1"
      )

			resolve()
  });
}
