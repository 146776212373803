import $ from "jquery";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
import MotionPathPlugin from "gsap/MotionPathPlugin";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import TextPlugin from "gsap/TextPlugin";
import Draggable from "gsap/Draggable";
import InertiaPlugin from "gsap/InertiaPlugin";
// import "bootstrap";
import { whyInvestTimeline, whyInvestTimeline2 } from "./sections/why-invest";
import { carouselTimeline, initCarousel } from "./sections/carousel";
gsap.registerPlugin(
  ScrollTrigger,
  DrawSVGPlugin,
  MotionPathPlugin,
  ScrollToPlugin,
  TextPlugin,
  InertiaPlugin,
  Draggable
);

import "./menu";
import { aboutSIATimeline, aboutSIATimeline2 } from "./sections/about-sia";
import { landscapeTimeline } from "./sections/landscape";
import { testimonialsTimeline } from "./sections/testimonials";
import { exploreSIATimeline } from "./sections/explore-sia";
import { whyInvestTimelineMobile, whyInvestTimelineMobile2 } from "./sections/why-invest-mobile";
import Parser from "rss-parser";
import dayjs from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat';
import colorJs from 'color';
dayjs.extend(advancedFormat)

const convertImages = async (query) => {
  return new Promise<void>((resolve, reject) => {
    const images = document.querySelectorAll(query);
    let imagesProcessed = 0;
    images.forEach((image) => {
      fetch(image.src)
        .then((res) => res.text())
        .then((data) => {
          const parser = new DOMParser();
          const svg = parser
            .parseFromString(data, "image/svg+xml")
            .querySelector("svg");
          if (svg) {
            if (image.id) svg.id = image.id;
            if (image.className) svg.classList.value = image.classList;

            image.parentNode.replaceChild(svg, image);
          }
        })
        .then(() => {
          imagesProcessed++;
          if (imagesProcessed === images.length) {
            resolve();
          }
        })
        .catch((error) => {
          console.error(error);
          reject();
        });
    });
    if (images.length === 0) {
      resolve();
    }
  });
};
export interface Region {
  region:   string;
  regionId: string;
  name:     string;
  color:    Color;
  link:     string;
  image:    string;
}

export interface Color {
  dark:  string;
  light: string;
}

export const RegionMapping = {
  //pilbara
  mitland: {
    region: "PILBARA",
    regionId: "pilbara",
    name: "MAITLAND SIA",
    color: {
      dark: "#D71D4B",
      light: "#ff4976",
    },
    link: "https://developmentwa.com.au/projects/industrial-and-commercial/maitland-sia/overview",
    image: require("./assets/img/2021_Maitland_15.jpg"),
  },
  burrup: {
    region: "PILBARA",
    regionId: "pilbara",
    name: "BURRUP SIA",
    color: {
      dark: "#D71D4B",
      light: "#ff4976",
    },
    link: "https://developmentwa.com.au/projects/industrial-and-commercial/burrup-sia/overview",
    image: require("./assets/img/SIA_Burrup.jpg"),
  },
  anketell: {
    region: "PILBARA",
    regionId: "pilbara",
    name: "ANKETELL SIA",
    color: {
      dark: "#D71D4B",
      light: "#ff4976",
    },
    link: "https://developmentwa.com.au/projects/industrial-and-commercial/anketell-sia/overview",
    image: require("./assets/img/SIA_Anketell.jpg"),
  },
  boodarie: {
    region: "PILBARA",
    regionId: "pilbara",
    name: "BOODARIE SIA",
    color: {
      dark: "#D71D4B",
      light: "#ff4976",
    },
    link: "https://developmentwa.com.au/projects/industrial-and-commercial/boodarie-sia/overview",
    image: require("./assets/img/SIA_Boodarie.jpg"),
  },
  ashburton: {
    region: "PILBARA",
    regionId: "pilbara",
    name: "ASHBURTON NORTH SIA",
    color: {
      dark: "#D71D4B",
      light: "#ff4976",
    },
    link: "https://developmentwa.com.au/projects/industrial-and-commercial/ashburton-north-sia/overview",
    image: require("./assets/img/Ashburton-North-SIA-Wheatstone.jpg"),
  },

  //kimberley
  browse: {
    region: "KIMBERLEY",
    regionId: "kimberley",
    name: "BROWSE LNG PRECINCT SIA",
    color: {
      light: "#d67a57",
      dark: "#D25E32",
    },
    link: "https://developmentwa.com.au/projects/industrial-and-commercial/browse-lng-precinct/overview",
    image: require("./assets/img/Browse-LNG-Precinct.jpg"),
  },

  //midwest
  oakajee: {
    region: "MID WEST: GERALDTON",
    regionId: "midwest",
    name: "OAKAJEE SIA",
    color: {
      dark: "#91A9B9",
      light: "#b3d6ed",
    },
    link: "https://developmentwa.com.au/projects/industrial-and-commercial/oakajee-sia/overview",
    image: require("./assets/img/SIA_Oakajee.jpg"),
  },

  //goldfields
  mungari: {
    region: "GOLDFIELDS",
    regionId: "goldfields",
    name: "MUNGARI SIA",
    color: {
      dark: "#BE9526",
      light: "#dab85a",
    },
    link: "https://developmentwa.com.au/projects/industrial-and-commercial/mungari-sia/overview",
    image: require("./assets/img/SIA_Mungari.jpg"),
  },

  //peel
  rockingham: {
    region: "PERTH",
    name: "ROCKINGHAM SIA",
    regionId: "Peel",
    color: {
      dark: "#8F7FB0",
      light: "#b19fd6",
    },
    link: "https://developmentwa.com.au/projects/industrial-and-commercial/rockingham-industry-zone/overview",
    image: require("./assets/img/SIA_Rockingham.jpg"),
  },
  kwinana: {
    region: "PERTH",
    name: "KWINANA SIA",
    regionId: "Peel",
    color: {
      dark: "#8F7FB0",
      light: "#b19fd6",
    },
    link: "https://developmentwa.com.au/projects/industrial-and-commercial/kwinana-sia/overview",
    image: require("./assets/img/SIA_Kwinana.jpg"),
  },

  //south west
  kemberton: {
    region: "SOUTH WEST",
    regionId: "southwest",
    name: "KEMERTON SIA",
    color: {
      dark: "#376C35",
      light: "#639e61",
    },
    link: "https://developmentwa.com.au/projects/industrial-and-commercial/kemerton-sia/overview",
    image: require("./assets/img/SIA_Kemerton.jpg"),
  },
  shotts: {
    region: "SOUTH WEST",
    regionId: "southwest",
    name: "SHOTTS SIA",
    color: {
      dark: "#376C35",
      light: "#639e61",
    },
    link: "https://developmentwa.com.au/projects/industrial-and-commercial/shotts-sia/overview",
    image: require("./assets/img/Shotts-SIA.jpg"),
  },

  //wheatbelt

  //great southern
  mirambeena: {
    region: "GREAT SOUTHERN",
    regionId: "greatsouthern",
    name: "MIRAMBEENA SIA",
    color: {
      dark: "#2F7DC1",
      light: "#79b9f1",
    },
    link: "https://developmentwa.com.au/projects/industrial-and-commercial/mirambeena-sia/overview",
    image: require("./assets/img/SIA_Mirambeena.jpg"),
  },
} as { [key: string]: Region };

async function init() {
  initFeed();
  await initCarousel();
  const { isDesktop, isMobile, isTablet, isTabletMobile } = display();

  // scroll down arrow animation
  gsap.to(".arrow", { opacity: 1, delay: 1 });
  gsap.to(".arrow", { y: 12, ease: "power1.inOut", repeat: -1, yoyo: true });
  
  const pulseTimelineDot = gsap.timeline({ repeat: -1, repeatRefresh: true });
      
  pulseTimelineDot.to("#CTA-map-dot #ring", { drawSVG: "-25% 75%" });
  pulseTimelineDot.to("#CTA-map-dot #ring", { duration: 4 });
  pulseTimelineDot.to("#CTA-map-dot #ring", { drawSVG: "0" });
  

  

  await carouselTimeline();
  if (isMobile) {
    await whyInvestTimelineMobile();
  } else {
    await whyInvestTimeline();
  }
  await aboutSIATimeline();
  if (isMobile) {
    await whyInvestTimelineMobile2();
  } else {
    await whyInvestTimeline2();
  }
  await aboutSIATimeline2();
  await landscapeTimeline();
  await testimonialsTimeline();
  await exploreSIATimeline();

  $("body").addClass("ready");

  // window.addEventListener("scroll", function () {
  //   requestAnimationFrame(check);
  // });
}

const initFeed = async() => {
  let parser = new Parser({
    customFields: {
      item: ['colour'],
    }
  });
  const newsUrl = `https://developmentwa.com.au/news/sia`;
  const feedURL = `${newsUrl}?format=feed&type=rss`;
  try {
    const feed = await parser.parseURL(feedURL);
    const items = feed.items;
    const lastIndex = items.length - 1;
    let currentIndex = 0;

    const updateFeed = (index, direction) => {
      const item = items[index];
      const sign = direction === "next" ? -1 : 1;
      const distance = 10;
      const clone = $('.news-ticker .news-ticker-item-content').clone().addClass("clone");
      
      if(item.colour){
        const color = colorJs(item.colour);

        $('.news-ticker').css("background-color", color.hex());
        $('.news-ticker .news-all-button').css("background-color", color.darken(0.2).hex());
      }else{
        $('.news-ticker').css("background-color", "");
        $('.news-ticker .news-all-button').css("background-color", "");
      }
      $('.news-ticker .news-ticker-item-text').text(`${item.title}${item.contentSnippet ? ` - ${item.contentSnippet}` : ''}`)
      $('.news-ticker .news-ticker-item-date').text(dayjs(item.isoDate).format("Do MMMM YYYY"))
      $('.news-ticker .news-ticker-item-text').attr("href", item.link || "#");
      clone.appendTo('.news-ticker .news-ticker-item')
      const timeline = gsap.timeline({
        onComplete: () => {
          clone.remove();
        }
      });
      timeline
        .to(".news-ticker .news-ticker-item-content.clone", {translateX: distance * sign, opacity: 0})
        .fromTo(".news-ticker .news-ticker-item-content:not(.clone)", {translateX: distance * sign, opacity: 0},{translateX: 0, opacity: 1}, "<")
      
      newsTickerCarousel.restart();
    }
  
    const setNext = () => {
      currentIndex++;
      if (currentIndex > lastIndex) {
        currentIndex = 0;
      }
      updateFeed(currentIndex, 'next');
    }
  
    const setPrev = () => {
      currentIndex--;
      if (currentIndex < 0) {
        currentIndex = lastIndex;
      }
      updateFeed(currentIndex, 'prev');
    }

    var newsTickerCarousel = gsap.timeline({repeat: -1});
    newsTickerCarousel
    .to(".news-ticker", {duration: 5})
    .call(() => {
      setNext();
    })

    $('.news-ticker .news-ticker-nav-next').on("click", () => {
      setNext()
    });
    $('.news-ticker .news-ticker-nav-prev').on("click", () => {
      setPrev();
    });


    $('.news-ticker .news-all-button').attr("href", feed.link || "");
    updateFeed(currentIndex, 'next');
    $('.news-ticker').addClass('active');
  } catch (error) {
    console.error(error);
    $('.news-ticker .news-ticker-container').hide();
    $('.news-ticker .news-ticker-nav').hide();
    $('.news-ticker .news-ticker-error').show();
    $('.news-ticker .news-ticker-error a').attr("href", newsUrl);
    $('.news-ticker').addClass("error");
    $('.news-ticker').addClass('active');
      
  }
  

  
}

export const onWhiteBackground = (isOnWhiteBackground: boolean) => {
  if (isOnWhiteBackground) {
    $(".react-to-white-background").addClass("on-white-background");
  } else {
    $(".react-to-white-background").removeClass("on-white-background");
  }
};
export interface displayOptions {
  default?: any;
  desktop?: any;
  tablet?: any;
  tabletMobile?: any;
  mobile?: any;
}

export const display = () => {
  const tabletWidth = 600;
  const desktopWidth = 1100;

  const windowWidth = window.innerWidth;
  return {
    isMobile: windowWidth <= tabletWidth - 1,
    isTablet: windowWidth >= tabletWidth && windowWidth <= desktopWidth - 1,
    isTabletMobile: windowWidth <= desktopWidth - 1,
    isDesktop: windowWidth >= desktopWidth,
  };
};

export const displaySelect = (displayOptions: displayOptions) => {
  const { isDesktop, isMobile, isTablet, isTabletMobile } = display();

  if (isMobile && "mobile" in displayOptions) {
    return displayOptions.mobile;
  }
  if (isTablet && "tablet" in displayOptions) {
    return displayOptions.tablet;
  }
  if (isTabletMobile && "tabletMobile" in displayOptions) {
    return displayOptions.tabletMobile;
  }
  if (isDesktop && "desktop" in displayOptions) {
    return displayOptions.desktop;
  }
  return displayOptions.default;
};

$(async () => {
  await convertImages(".svg-image");
  forceTop();
  init();

  let lasKnown_isMobile = display().isMobile;
  //force reload when resizing to mobile device
  $(window).on("resize", function (e) {
    let current_isMobile = display().isMobile;
    if (lasKnown_isMobile != current_isMobile){
      window.location.reload(); 
    }
  });
});

$(window).on("beforeunload", function () {
  forceTop();
});

export const forceTop = () => {
  $(window).scrollTop(0);
};
