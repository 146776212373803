import { gsap } from "gsap";
import $ from "jquery";
import { makeAsideSectionActive } from "../menu";
const range = (min, max) =>
  Array.from({ length: max - min + 1 }, (_, i) => min + i);

export async function landscapeTimeline() {
  const shrubsTimeline = () => {
    const shrubs = range(1, 27);
    const small = [1, 4, 6, 8, 11, 13, 16, 18, 21, 23, 25];
    const medium = [24, 19, 14, 9, 2];
    const large = [27, 26, 22, 20, 17, 15, 12, 10, 7, 5, 3];
    const shrubTimeline = gsap.timeline();
    shrubTimeline
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s1",
        { translateY: "100%" },
        { translateY: "0%", duration: 2 },
        9
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s2",
        { translateY: "100%" },
        { translateY: "0%", duration: 2 },
        10
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s3",
        { translateY: "100%" },
        { translateY: "0%", duration: 2 },
        11
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s4",
        { translateY: "100%" },
        { translateY: "0%", duration: 2 },
        14
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s5",
        { translateY: "100%" },
        { translateY: "0%", duration: 4 },
        15
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s6",
        { translateY: "100%" },
        { translateY: "0%", duration: 2 },
        18
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s7",
        { translateY: "100%" },
        { translateY: "0%", duration: 2 },
        20
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s8",
        { translateY: "100%" },
        { translateY: "0%", duration: 2 },
        25
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s9",
        { translateY: "100%" },
        { translateY: "0%", duration: 2 },
        28
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s10",
        { translateY: "100%" },
        { translateY: "0%", duration: 2 },
        30
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s11",
        { translateY: "100%" },
        { translateY: "0%", duration: 2 },
        32
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s12",
        { translateY: "100%" },
        { translateY: "0%", duration: 2 },
        36
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s13",
        { translateY: "100%" },
        { translateY: "0%", duration: 3 },
        39
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s14",
        { translateY: "100%" },
        { translateY: "0%", duration: 2 },
        42
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s15",
        { translateY: "100%" },
        { translateY: "0%", duration: 2 },
        44
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s16",
        { translateY: "100%" },
        { translateY: "0%", duration: 4 },
        48
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s17",
        { translateY: "100%" },
        { translateY: "0%", duration: 3 },
        50
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s18",
        { translateY: "100%" },
        { translateY: "0%", duration: 2 },
        54
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s19",
        { translateY: "100%" },
        { translateY: "0%", duration: 2 },
        57
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s20",
        { translateY: "100%" },
        { translateY: "0%", duration: 4 },
        59
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s21",
        { translateY: "100%" },
        { translateY: "0%", duration: 2 },
        61
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s22",
        { translateY: "100%" },
        { translateY: "0%", duration: 4 },
        64
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s23",
        { translateY: "100%" },
        { translateY: "0%", duration: 2 },
        68
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s24",
        { translateY: "100%" },
        { translateY: "0%", duration: 3 },
        71
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s25",
        { translateY: "100%" },
        { translateY: "0%", duration: 2 },
        72
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s26",
        { translateY: "100%" },
        { translateY: "0%", duration: 3 },
        76
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs #s27",
        { translateY: "100%" },
        { translateY: "0%", duration: 3 },
        79
      );

    // .to('#landscape .landscape-image #Available-shrubs', {duration: 100},0)
    // shrubs.forEach(i => {
    // 	shrubTimeline
    // 		.fromTo(`#landscape .landscape-image #Available-shrubs #s${i}`, {translateY: "80%"}, {translateY: "0%", duration: 2},">0.8")
    // })

    return shrubTimeline;
  };

  const availableTextTimeline = () => {
    const getHeightToGo = () => {
      const times = Math.ceil((window.innerHeight + 200) / 200);
      const heightToGo = times * 200 + 40;
      return heightToGo;
    };
    const availableTextTimeline = gsap.timeline();
    availableTextTimeline;
    // .fromTo(
    // 	"#landscape .text-container-outer .text-1",
    // 	{ translateX: () => (window.innerWidth + 500) },
    //   { translateX: () => -(window.innerWidth + 500), duration: 30 },
    // 	"<+=30%"
    // )
    // .to("#landscape", {duration: 100})
    // .fromTo(
    //   "#landscape .text-container .text",
    // 	{ translateY: "40px" },
    //   { translateY: () => `-${getHeightToGo()}px`, duration: 15, stagger: 10 },
    //   21
    // )

    return availableTextTimeline;
  };

  const treesTimeline = () => {
    const treesTimeline = gsap.timeline();
    treesTimeline
      .fromTo(
        "#landscape .landscape-image #Available-trees > g:nth-of-type(2) > g",
        { translateY: "100%" },
        { translateY: "0%", duration: 2, stagger: 2 },
        10
      )
      .fromTo(
        "#landscape .landscape-image #Available-trees > g:nth-of-type(3) > g",
        { translateY: "100%" },
        { translateY: "0%", duration: 2, stagger: 2 },
        39
      )
      .fromTo(
        "#landscape .landscape-image #Available-trees > g:nth-of-type(1) > g",
        { translateY: "100%" },
        { translateY: "0%", duration: 2, stagger: 2 },
        78
      );

    return treesTimeline;
  };

  const foregroundTimeline = () => {
    //wind spinning
    gsap.set("#landscape .landscape-image #available-wind > g > g", {
      transformOrigin: "50% 65%",
    });
    gsap.to("#landscape .landscape-image #available-wind > g#fan > g", {
      rotate: "360deg",
      ease: "none",
      repeat: -1,
      duration: 4,
      delay: 1,
    });
    gsap.to("#landscape .landscape-image #available-wind > g#fan-2 > g", {
      rotate: "-360deg",
      ease: "none",
      repeat: -1,
      duration: 3,
      delay: 2,
    });
    gsap.to("#landscape .landscape-image #available-wind > g#fan-3 > g", {
      rotate: "360deg",
      ease: "none",
      repeat: -1,
      duration: 5,
    });

    //solar shimming
    // gsap.fromTo("#landscape .landscape-image #available-solar > g#main > rect.cls-14", {fill: "#f7ac47"}, {fill: "#fec474", ease: "none", duration: 1, stagger:{
    // 	from: "random",
    // 	amount: -0.5,
    // 	 repeat: -1,
    // 	 yoyo: true
    // }, repeatRefresh: true, })
    // let tl = gsap.timeline({repeatRefresh: true, repeat: -1});
    // tl
    // .fromTo("#landscape .landscape-image #available-solar > g#main > rect.cls-17", {fill: "#fec47"}, {fill: "#f7ac47", ease: "none", duration:2, stagger:{
    // 	from: "random",
    // 	amount: -0.5,
    // } })

    //crane moving
    gsap.to("#landscape .landscape-image #available-ship #crane", {
      translateX: "150%",
      repeat: -1,
      duration: 5,
      yoyo: true,
      ease: "none",
      yoyoEase: "none",
    });
    gsap.to("#landscape .landscape-image #available-ship #ship", {
      translateX: "5%",
      repeat: -1,
      duration: 5,
      yoyo: true,
      ease: "none",
      yoyoEase: "none",
    });
    gsap.fromTo(
      "#landscape .landscape-image #available-ship #ship",
      { rotate: "-2deg", transformOrigin: "100% 50%" },
      {
        rotate: "2deg",
        repeat: -1,
        duration: 3,
        yoyo: true,
        ease: "none",
        yoyoEase: "none",
      }
    );

    //sun rotating
    gsap.set("#landscape .landscape-image #available-solar #sun", {
      transformOrigin: "50% 50%",
    });
    gsap.to("#landscape .landscape-image #available-solar #sun", {
      rotate: "360deg",
      ease: "none",
      repeat: -1,
      duration: 8,
    });

    const foregroundTimeline = gsap.timeline();
    foregroundTimeline
      //building 1
      .fromTo(
        "#landscape .landscape-image #available-building > g",
        { translateY: "100%" },
        { translateY: "0%", stagger: 1, duration: 2 },
        22
      )
      //oil
      .fromTo(
        "#landscape .landscape-image #available-oil > g",
        { translateY: "100%" },
        { translateY: "0%", stagger: 1, duration: 2 },
        35
      )
      //wind
      .fromTo(
        "#landscape .landscape-image #available-wind > g",
        { translateY: "100%" },
        { translateY: "0%", stagger: 1, duration: 2 },
        47
      )
      .fromTo(
        "#landscape .landscape-image #available-wind > g > g",
        { opacity: 0, translateY: "5%", scale: 0 },
        { opacity: 1, translateY: "0%", scale: 1, stagger: 1, duration: 1 },
        48
      )

      //solar
      .fromTo(
        "#landscape .landscape-image #available-solar > g:not(#sun)",
        { translateY: "100%" },
        { translateY: "0%", stagger: 1, duration: 2 },
        55
      )

      //boat
      .fromTo(
        "#landscape .landscape-image #available-ship #arms",
        { translateY: "100%" },
        { translateY: "0%", duration: 2 },
        68
      )
      .fromTo(
        "#landscape .landscape-image #available-ship #ship",
        { translateY: "100%" },
        { translateY: "10%", stagger: 1, duration: 2 },
        69
      );
    return foregroundTimeline;
  };

  return new Promise<void>((resolve) => {
    const endDuration = "1000%";
    // const landscapeViewBox = "0 0 6896.59 533";
    const landscapeViewBox = "0 0 6896.59 533";

    //sway
    gsap.fromTo(
      "#landscape .landscape-image #Available-shrubs-container",
      { translateX: "-0.5%" },
      {
        translateX: "+0.5%",
        ease: "none",
        duration: 20,
        repeat: -1,
        yoyo: true,
        yoyoEase: "none",
      }
    );

    let landscapeTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: "#landscape",
        start: "center center",
        pin: true,
        end: endDuration,
        pinSpacing: true,
        scrub: true,
        invalidateOnRefresh: true,
        onEnter: () => {
          // 16/11/2023 quick fix to change the z-index stacking to have a link in the text
          $("#about-sia-2 #available").css({ "z-index": "1001", "position": "static" });
          makeAsideSectionActive("landscape");
        },
        onEnterBack: () => {
        },
      },
    });
    landscapeTimeline
      .set("#landscape .landscape-image", {
        attr: { viewBox: landscapeViewBox },
      })
      .fromTo("#landscape", { opacity: 0 }, { opacity: 1, duration: 1 }, 1)

      .fromTo(
        "#landscape .landscape-image > #main",
        { translateX: "55%" },
        { translateX: "-50%", ease: "none", duration: 100 }
      )
      .fromTo(
        "#landscape .landscape-image #available-truck",
        { translateX: "-800%" },
        { translateX: "1950%", ease: "none", duration: 70 },
        "<"
      )

      //parallax
      .fromTo(
        "#landscape .landscape-image #Available-power-lines",
        { translateX: "0%" },
        { translateX: "15%", ease: "none", duration: 100 },
        "<"
      )
      .fromTo(
        "#landscape .landscape-image #Available-shrubs",
        { translateX: "-0%" },
        { translateX: "5%", ease: "none", duration: 100 },
        "<"
      );
    // .fromTo(
    //   "#landscape .text-container-outer .text",
    //   { opacity: 0 },
    //   { opacity: 1, duration: 15, stagger: 10 },
    //   21
    // )
    const makeLandAllocationTextActive = (number) => {
      $("#landscape .text-progress-dot").removeClass("active");
      $(`#landscape .text-progress-dot.text-${number}-progress`).addClass(
        "active"
      );
    };
    landscapeTimeline
      //fade in powerlines
      .fromTo(
        "#landscape .landscape-image #Available-power-lines",
        { opacity: 1 },
        { opacity: 1, duration: 5 },
        "<+5"
      )
      .add(shrubsTimeline(), 0)
      // .add(availableTextTimeline(), 0)

      .fromTo(
        "#landscape .text-container-outer .text-1",
        { translateX: "100%" },
        {
          translateX: "-100%",
          duration: 15,
          ease: "none",
          onStart: () => {
            makeLandAllocationTextActive(1);
          },
        },
        14
      )

      .fromTo(
        "#landscape .text-container-outer .text-2",
        { translateX: "100%" },
        {
          translateX: "-100%",
          duration: 15,
          ease: "none",
          onStart: () => {
            makeLandAllocationTextActive(2);
          },
          onReverseComplete: () => {
            makeLandAllocationTextActive(1);
          },
        },
        22
      )

      .fromTo(
        "#landscape .text-container-outer .text-3",
        { translateX: "100%" },
        {
          translateX: "-100%",
          duration: 15,
          ease: "none",
          onStart: () => {
            makeLandAllocationTextActive(3);
          },
          onReverseComplete: () => {
            makeLandAllocationTextActive(2);
          },
        },
        30
      )

      .fromTo(
        "#landscape .text-container-outer .text-4",
        { translateX: "100%" },
        {
          translateX: "-100%",
          duration: 15,
          ease: "none",
          onStart: () => {
            makeLandAllocationTextActive(4);
          },
          onReverseComplete: () => {
            makeLandAllocationTextActive(3);
          },
        },
        38
      )

      .fromTo(
        "#landscape .text-container-outer .text-5",
        { translateX: "100%" },
        {
          translateX: "-100%",
          duration: 15,
          ease: "none",
          onStart: () => {
            makeLandAllocationTextActive(5);
          },
          onReverseComplete: () => {
            makeLandAllocationTextActive(4);
          },
        },
        47
      )

      .fromTo(
        "#landscape .text-container-outer .text-6",
        { translateX: "100%" },
        {
          translateX: "-100%",
          duration: 15,
          ease: "none",
          onStart: () => {
            makeLandAllocationTextActive(6);
          },
          onReverseComplete: () => {
            makeLandAllocationTextActive(5);
          },
        },
        55
      )

      .fromTo(
        "#landscape .text-container-outer .text-7",
        { translateX: "100%" },
        {
          translateX: "-100%",
          duration: 15,
          ease: "none",
          onStart: () => {
            makeLandAllocationTextActive(7);
          },
          onReverseComplete: () => {
            makeLandAllocationTextActive(6);
          },
        },
        62
      )

      .fromTo(
        "#landscape .text-container-outer .text-8",
        { translateX: "100%" },
        {
          translateX: "-100%",
          duration: 15,
          ease: "none",
          onStart: () => {
            makeLandAllocationTextActive(8);
          },
          onReverseComplete: () => {
            makeLandAllocationTextActive(7);
          },
        },
        72
      )

      .add(treesTimeline(), 0)
      .add(foregroundTimeline(), 0);

    resolve();
  });
}
