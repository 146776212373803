import { gsap } from "gsap";
import { displaySelect } from "..";

const itemColors = {
  1: "#FBB03F",
  2: "#D60F48",
  3: "#D60F48",
  4: "#D35E31",
  5: "#D60F48",
  6: "#BD9426",
  7: "#71BF44",
  8: "#D60F48",
  9: "#7BC351",
  10: "#D60F48",
  11: "#277DC1",
  12: "#8FA9B8",
  13: "#8f7fb0",
  14: "#8f7fb0",
}
const itemColorsLight = {
  1: "#fcc26c",
  2: "#d63e69",
  3: "#d63e69",
  4: "#cf724d",
  5: "#d63e69",
  6: "#91711c",
  7: "#538536",
  8: "#d63e69",
  9: "#56863a",
  10: "#d63e69",
  11: "#4a90c5",
  12: "#a4b3bd",
  13: "#a298b8",
  14: "#a098b1",
}


export function carouselTimeline() {
  return new Promise<void>((resolve, reject) => {
    
    let timeline = gsap.timeline({
      scrollTrigger: {
        trigger: "#carousel",
        start: "top top",
        pin: true,
        pinSpacing: false,
        end: () => displaySelect({mobile: "0", default: "+=100%"}),
        scrub: true,
      },
    });
  
    timeline
      .addLabel("carousel-start")
      .fromTo("#why-invest-1", {opacity: 0,}, {opacity: 1, duration: 1,})
      .fromTo("#why-invest-1 .map-image #Map-WA-Background", {opacity: 0},{ opacity: 1, duration: 2 }, "")
  
      .addLabel("carousel-end");
  
    resolve()
  });
}

export function initCarousel() {
  return new Promise<void>((resolve, reject) => {
    
    let timeline = gsap.timeline({
      repeat: -1,
      repeatRefresh: true,
      onRepeat: () => {
        currentItem++;
        randomSign = gsap.utils.random([1,-1]);
        if(currentItem>(len)){
          currentItem = 1;
          prevItem = len
        }else{
          prevItem = currentItem-1
        }
      }
    });
    let currentItem = 1;
    let prevItem = -1;
    let randomSign = 1;
    const len = Object.keys(itemColors).length;
    //background
    if(currentItem !== 1){
    }
    timeline.set("#carousel .background .background-image", {transformOrigin:"left"})
    timeline.set("#carousel .background-overlay", {transformOrigin:"left", scaleX: 0, backgroundColor: () => {return itemColors[currentItem]}})
    timeline.set(".scroll-down #CTA-button", {background: () => {return itemColors[currentItem]}})
    // timeline.set(".news-ticker", {background: () => {return itemColors[currentItem]}})
    // timeline.set(".news-ticker .news-all-button", {background: () => {return itemColorsLight[currentItem]}})
    timeline.to("#carousel .background-overlay", { scaleX: () => currentItem === 1 ? "0": "100%", duration: 0.5})
    timeline.to("#carousel .overlay-1", { height: 0, duration: 0.2, ease: "power2"}, "<0.15");
    timeline.to("#carousel .overlay-2", { height: 0, duration: 0.2, ease: "power2"}, "<0.15");
    timeline.to("#carousel .overlay-3", { height: 0, duration: 0.2, ease: "power2"}, "<0.15");
    
    timeline.call(() => {
      gsap.to(`#carousel .logo #logo-${prevItem}`, {opacity: 0})
      gsap.to(`#carousel .logo #logo-${currentItem}`, {opacity: 1})
      gsap.to(`#carousel .background #image-${prevItem}`, {opacity: 0,delay: 0.2},)
      gsap.to(`#carousel .background #image-${currentItem}`, {opacity: 1,delay: 0.2},)
    }, undefined, "<-0.3")
  
  
    timeline.to("#carousel .background-overlay", {transformOrigin: "right", scaleX: 0, duration: 0.4},">0.5")
  
  
    timeline.to("#carousel .overlay-1", {backgroundColor: () => {return itemColors[currentItem]}, height: 1050, duration: 1, rotate: () => {return gsap.utils.random(8, 15, 1) * randomSign}, ease: "power2" },">-=1");
    timeline.to("#carousel .overlay-2", {backgroundColor: () => {return itemColors[currentItem]}, height: 1200, duration: 1, rotate: () => {return gsap.utils.random(10, 18, 1) * -randomSign}, ease: "power2" },">-=0.5");
    timeline.to("#carousel .overlay-3", {backgroundColor: () => {return itemColors[currentItem]}, height: 1050, duration: 1, rotate: () => {return gsap.utils.random(10, 18, 1) * randomSign}, ease: "power2" },">-=0.5");
  
    timeline.to("#carousel", {}, 4)
    gsap.to("#carousel .overlay-outer", {translateY: "+=10", repeat: -1, yoyo: true, duration: 10, ease: "none", yoyoEase: "none"})
    
  
    
  
    let patternTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: "#carousel .carousel-inner",
        end: "+=180%",
        start: "top top",
        scrub: 0.5,
      },
    });
    patternTimeline
      .to(".news-ticker", {autoAlpha: 0, duration: 0.01}, 0)
      .fromTo('header .header-logo svg', {height: 50}, {height: 130, duration: 0.02}, 0)
      .to("#carousel .overlay-1", { scaleY: 0, duration: 1 }, 0)
      .to("#carousel .overlay-2", { scaleY: 0, duration: 1 }, 0)
      .to("#carousel .overlay-3", { scaleY: 0, duration: 1 }, 0)

    resolve()
  })
}
