import { gsap } from "gsap";
import $ from "jquery";
import { displaySelect, onWhiteBackground } from "..";
import { makeAsideSectionActive } from "../menu";
const makeSectionActive = (section) => {
  $("#SIAS-are-content .active").removeClass("active");
  $(`#SIAS-are-content a[href='#${section}']`).addClass("active");
};

export async function aboutSIATimeline() {
  return new Promise<void>((resolve) => {
    const duration = 2;

    let aboutSIATimeline = gsap.timeline({
      defaults: {
        duration: duration,
      },
      scrollTrigger: {
        trigger: "#about-sia",
        start: "top top",
        end: "150%",
        pinSpacing: true,
        // anticipatePin: 1,
        pin: true,
        scrub: true,
        invalidateOnRefresh: true, 
        // snap: {
        //   snapTo: "labelsDirectional",
        //   delay: 1,
        // },
        onEnterBack: () => {
          makeAsideSectionActive("about-sia");
          // $(".scroll-content").addClass("background-gold")
        },
      },
    });

   aboutSIATimeline
      // .set("#about-sia .content-outer", {translateY: "60%"})
      .addLabel("available-heading-shown")
      .set("#about-sia #about-sia-background", {
        scaleX: 1,
        scaleY: 1,
        skewY: 17,
        transformOrigin: "bottom left",
        translateY: "20%",
      })
      .set("#about-sia #about-SIA-content", { translateY: "40%" })
      // .set("#about-sia .connected-image", { translateY: "-15%" })
      // // .set("#about-sia .connected-image", { scale: () => displaySelect({mobile: 2, tablet: 1.5, default: 1.2}) })
      .to("#about-sia", { duration: 4})
      .to(
        "#about-sia #about-sia-background",
        { translateY: "0", duration: duration * 2, skew: 0 },
        // "<"
        )
        .to("#why-invest-1", { opacity: 0 }, "<")
        .call(() => {
          makeAsideSectionActive("about-sia");
        }, undefined, "<+2")
      .to(
        "#about-sia #about-sia-background",
        { skewY: 0, duration: duration * 2 },
        "<"
      )
      .to(
        "#about-sia #about-SIA-content",
        { translateY: "-0%", duration: duration * 1 },
        "<"
      )
      .addLabel("available-heading-shown-half", `<${duration}`)
      // .to("#about-sia #about-SIA-content", { opacity: 0}, `>-${duration*3}`)
      .addLabel("available-heading-start")
      // .to("#about-sia", { duration: 2 })
      
      .to(
        "#about-sia #about-sia-background",
        { opacity: 1, background: "linear-gradient(195deg, #f6ab47 5%, #f6ab47 50%)"},
        ">"
        )
      .fromTo("#why-invest-2", { opacity: 0 },{ opacity: 1 }, "<3")
    

   
      
      
    resolve();
  });
}

export async function aboutSIATimeline2() {
  return new Promise<void>((resolve) => {
    const duration = 2;

    let aboutSIATimeline2 = gsap.timeline({
      defaults: {
        duration: duration,
      },
      scrollTrigger: {
        trigger: "#about-sia-2",
        start: "top top",
        end: "+=1000%",
        pinSpacing: true,
        anticipatePin: 1,
        pin: true,
        scrub: true,
        invalidateOnRefresh: true,
        // snap: {
        //   snapTo: "labelsDirectional",
        //   delay: 1,
        // },
        onEnterBack: () => {
          makeAsideSectionActive("SIAS-are-content");
        },
        onEnter: () => {
          makeAsideSectionActive("SIAS-are-content");
        },
      },
    });


    const lineTimelineAppear = gsap.timeline();
    lineTimelineAppear
      .fromTo(
        "#about-sia-2 .connected-image #SIA-connections-abroad-lines path",
        { drawSVG: "0" },
        { duration: 3,   drawSVG: "100%" }
      )
      .fromTo(
        "#about-sia-2 .connected-image #SIA-connections-abroad-lines path:nth-of-type(5)",
        { drawSVG: "100% 100%" },
        { duration: 3,   drawSVG: "0 100%" },
        "<"
      )
      .fromTo(
        "#about-sia-2 .connected-image #SIA-connections-abroad-lines path:nth-of-type(8)",
        { drawSVG: "100% 100%" },
        { duration: 3,   drawSVG: "0 100%" },
        "<"
      )
      .fromTo(
        "#about-sia-2 .connected-image #SIA-connections-abroad-lines path:nth-of-type(13)",
        { drawSVG: "100% 100%" },
        { duration: 3,   drawSVG: "0 100%" },
        "<"
      )
      .fromTo(
        "#about-sia-2 .connected-image #SIA-connections-abroad-lines path:nth-of-type(15)",
        { drawSVG: "100% 100%" },
        { duration: 3,   drawSVG: "0 100%" },
        "<"
      )
      .fromTo(
        "#about-sia-2 .connected-image #SIA-connections-abroad-lines path:nth-of-type(16)",
        { drawSVG: "100% 100%" },
        { duration: 3,   drawSVG: "0 100%" },
        "<"
      );

    const lineTimelineDisappear = gsap.timeline();
    lineTimelineDisappear
      .fromTo(
        "#about-sia-2 .connected-image #SIA-connections-abroad-lines path",
        { drawSVG: "0 100%" },
        { duration: 1.3, drawSVG: "100% 100%" }
      )
      .fromTo(
        "#about-sia-2 .connected-image #SIA-connections-abroad-lines path:nth-of-type(5)",
        { drawSVG: "0 100%" },
        { duration: 3,   drawSVG: "0 0" },
        "<"
      )
      .fromTo(
        "#about-sia-2 .connected-image #SIA-connections-abroad-lines path:nth-of-type(8)",
        { drawSVG: "0 100%" },
        { duration: 3,   drawSVG: "0 0" },
        "<"
      )  
        .fromTo(
        "#about-sia-2 .connected-image #SIA-connections-abroad-lines path:nth-of-type(13)",
        { drawSVG: "0 100%" },
        { duration: 3,   drawSVG: "0 0" },
        "<"
      )
      .fromTo(
        "#about-sia-2 .connected-image #SIA-connections-abroad-lines path:nth-of-type(15)",
        { drawSVG: "0 100%" },
        { duration: 3,   drawSVG: "0 0" },
        "<"
      )
      .fromTo(
        "#about-sia-2 .connected-image #SIA-connections-abroad-lines path:nth-of-type(16)",
        { drawSVG: "0 100%" },
        { duration: 3,   drawSVG: "0 0" },
        "<"
      );

    
      
      aboutSIATimeline2
      .set("#about-sia-2 #about-sia-background", {
        scaleX: () => displaySelect({ tabletMobile: 0, default: 0.35 }),
        // scaleY: () => displaySelect({ tabletMobile: "20%", default: undefined }),
        opacity: 1,
        transformOrigin: () => displaySelect({ default: "left center" }),
        onStart: () => {
          onWhiteBackground(true)
        },
        onComplete: () => {
          onWhiteBackground(true)
        },
        onReverseComplete: () => {
          onWhiteBackground(false)
        },
      })
      .call(
        () => {
          // makeAsideSectionActive("about-sia");
        },
        undefined,
        "<"
      )
      .to("#about-sia-2 .header-image", { opacity: 0 }, `<`)
      .set(
        "#about-sia-2 #SIAS-are-content",
        { opacity: 1, translateY: "0%" },
        ">-1"
      )
      .to(
        "#about-sia-2 #about-SIA-content",
        { opacity: 0, translateY: "5%", duration: duration * 0.5 },
        "<"
      )
      .call(
        () => {
          makeSectionActive("strategic");
          makeAsideSectionActive("SIAS-are-content");
        },
        undefined,
        "<"
      )
      .to("#about-sia-2 #strategic", { opacity: 1, duration: duration }, "<1")
      .fromTo(
        "#about-sia-2 .strategic-image circle",
        { drawSVG: "0" },
        { drawSVG: "100%", duration: duration * 0.5 },
        "<"
      )
      .fromTo(
        "#about-sia-2 .strategic-image #ppl-middle path",
        { drawSVG: "0" },
        { drawSVG: "100%", duration: duration * 0.5 }
      )
      .addLabel("strategic-start", ">-0.1")
      .fromTo(
        "#about-sia-2 .strategic-image #ppl-middle path.cls-3",
        { opacity: "0" },
        { opacity: "100%", duration: duration * 0.5 }
      )

      .fromTo(
        "#about-sia-2 .strategic-image #ppl-left path",
        { drawSVG: "0" },
        { drawSVG: "100%", duration: duration * 0.5 },
        ">-1"
      )
      .fromTo(
        "#about-sia-2 .strategic-image #ppl-left path.cls-1",
        { opacity: "0" },
        { opacity: "100%", duration: duration * 0.5 },
        ">-1"
      )

      .fromTo(
        "#about-sia-2 .strategic-image #ppl-right path",
        { drawSVG: "0" },
        { drawSVG: "100%", duration: duration * 0.5 },
        ">-1"
      )
      .fromTo(
        "#about-sia-2 .strategic-image #ppl-right path.cls-1",
        { opacity: "0" },
        { opacity: "100%", duration: duration * 0.5 },
        ">-1"
      )
      .to("#about-sia-2 #strategic", { duration: duration * 0.5 })
      .addLabel("strategic-end", "<")
      .to("#about-sia-2 #strategic", {
        translateY: "-100%",
        duration: duration * 2,
      })
      .call(
        () => {
          makeSectionActive("strategic");
        },
        undefined,
        "<"
      )
      .fromTo(
        "#about-sia-2 #planned",
        { translateY: "10%" },
        { translateY: "-100%", duration: duration * 2 },
        "<"
      )
      .call(
        () => {
          makeSectionActive("planned");
        },
        undefined,
        "<"
      )
      .fromTo(
        "#about-sia-2 .planned-image #IPZ-Gradient polygon",
        { opacity: 0 },
        { opacity: 1, duration: duration }
      )
      .fromTo(
        "#about-sia-2 .planned-image #IPZ-Gradient",
        { translateY: "0%", translateX: "50%", scale: 0 },
        { translateY: "0%", translateX: "0%", scale: 1, duration: duration },
        "<"
      )
      .fromTo(
        "#about-sia-2 .planned-image #IPZ-Large-Circle",
        { translateY: "-42%", translateX: "125%", scale: 0 },
        { translateY: "0%", translateX: "0%", scale: 1, duration: duration },
        "<"
      )
      .addLabel("planned-start", "<1")
      .fromTo(
        "#about-sia-2 .planned-image #IPZ-Small-Circle",
        { opacity: 0 },
        { opacity: 1, duration: duration }
      )
      .fromTo(
        "#about-sia-2 .planned-image #IPZ-Text",
        { opacity: 0 },
        { opacity: 1, duration: duration },
        "<"
      )
      .addLabel("planned-end")
      .to("#about-sia-2 #planned", { duration: duration * 0.5 })

      .to("#about-sia-2 #planned", {
        translateY: "-200%",
        duration: duration * 2,
      })
      .call(
        () => {
          makeSectionActive("planned");
        },
        undefined,
        "<"
      )
      .fromTo(
        "#about-sia-2 #connected",
        { translateY: "-100%" },
        { translateY: "-200%", duration: duration * 2 },
        "<"
      )
      .call(
        () => {
          makeSectionActive("connected");
        },
        undefined,
        "<"
      )
      .call(() => {
        makeSectionActive("connected");
      })
      .fromTo(
        "#about-sia-2 .connected-image #SIA-connections-WA .cls-3",
        { opacity: 0 },
        { duration: 1, opacity: 1 }
      )
      .fromTo(
        "#about-sia-2 .connected-image #SIA-connections-WA text",
        { opacity: 0 },
        { duration: 1, opacity: 1 },
        "<"
      )
      .fromTo(
        "#about-sia-2 .connected-image #SIA-connections-dots",
        { opacity: 0 },
        { opacity: 1 },
        "<"
      )
      .addLabel("connected-start")
      .fromTo(
        "#about-sia-2 .connected-image #SIA-connections-road",
        { opacity: 0 },
        { opacity: 1 }
      )
      .fromTo(
        "#about-sia-2 .connected-image #SIA-key-road",
        { opacity: 0 },
        { opacity: 1 },
        "<"
      )
      .addLabel("connected-road")

      .fromTo(
        "#about-sia-2 .connected-image #SIA-connections-rail",
        { opacity: 0 },
        { opacity: 1 }
      )
      .fromTo(
        "#about-sia-2 .connected-image #SIA-key-rail",
        { opacity: 0 },
        { opacity: 1 },
        "<"
      )
      .addLabel("connected-road1")
      .fromTo(
        "#about-sia-2 .connected-image #SIA-connections-gas",
        { opacity: 0 },
        { opacity: 1 }
      )
      .fromTo(
        "#about-sia-2 .connected-image #SIA-key-gas",
        { opacity: 0 },
        { opacity: 1 },
        "<"
      )

      .addLabel("connected-road2")

      .to("#about-sia-2 .connected-image #SIA-key-gas", {
        opacity: 0,
        duration: duration * 0.5,
      })
      .to(
        "#about-sia-2 .connected-image #SIA-key-rail",
        { opacity: 0, duration: duration * 0.5 },
        "<"
      )
      .to(
        "#about-sia-2 .connected-image #SIA-key-road",
        { opacity: 0, duration: duration * 0.5 },
        "<"
      )
      .to(
        "#about-sia-2 .connected-image #SIA-connections-road",
        { opacity: 0, duration: duration * 0.5 },
        "<"
      )
      .to(
        "#about-sia-2 .connected-image #SIA-connections-rail",
        { opacity: 0, duration: duration * 0.5 },
        "<"
      )
      .to(
        "#about-sia-2 .connected-image #SIA-connections-gas",
        { opacity: 0, duration: duration * 0.5 },
        "<"
      )

      .fromTo(
        "#about-sia-2 .cover-text.text-2",
        { opacity: 0 },
        { opacity: 1 },
        "<"
      )
      .to("#about-sia-2 .cover-text.text-1", { opacity: 0 }, "<")

      .fromTo(
        "#about-sia-2 .connected-image #SIA-connections-AUS-lines path",
        { opacity: 0 },
        { opacity: 1 },
        "<"
      )
      .add(lineTimelineAppear)
      .addLabel("connected-mid")
      .add(lineTimelineDisappear)

      .addLabel("connected-end")
      .to("#about-sia-2 #connected", { duration: duration })
      .to("#about-sia-2 #connected", {
        translateY: "-300%",
        duration: duration * 2,
      })
      .call(
        () => {
          makeSectionActive("connected");
        },
        undefined,
        "<"
      )
      .fromTo(
        "#about-sia-2 #available",
        { translateY: "-200%" },
        {
          translateY: "-300%",
          duration: duration * 2,
          onStart: () => {
            // 16/11/2023 quick fix to change the z-index stacking to have a link in the text
            $("#about-sia-2 #available").css({"z-index":"1001","position":"relative"});
          },
          onComplete: () => {
            onWhiteBackground(false);
           
          },
          onReverseComplete: () => {
            onWhiteBackground(true);
        
          },
        },

        "<"
      )
      .addLabel("available-start")
      .fromTo(
        "#about-sia-2 #available .cover-text",
        { translateY: "100%" },
        { translateY: "-30%", duration: 2 },
        ">-1"
      )
      .call(
        () => {
          makeSectionActive("available");
           
        },
        undefined,
        "<"
      )
      .call(() => {
        makeSectionActive("available");
        // 16/11/2023 quick fix to change the z-index stacking to have a link in the text
        $("#about-sia-2 #available").css({"z-index":"1001","position":"relative"});
      })
      .addLabel("available-middle")
      .to("#about-sia-2 #available", { duration: 1 })
      .to("#about-sia-2 #about-sia-background", { scaleX: 1, duration: 2 })

      .to(
        "#about-sia-2 .content-container",
        { autoAlpha: "0", duration: 0.5 },
        "<"
      )
      
      .to("#about-sia-2 #about-sia-background", {
        transformOrigin: "right center",
        scaleX:-0.5,
        duration: 4,
      })
      .to("#about-sia-2 .right-side", { autoAlpha: "0", duration: 0.5 }, "<")
      .addLabel("available-end");
    // .to("#about-sia", {duration: 100})
    resolve();
    
    return aboutSIATimeline;
    
  });
}