/* Menu JS */
import $ from "jquery";
import { gsap } from "gsap";

export const makeAsideSectionActive = (section?: string) => {
	//make sure side nav item is not active //make sure main site logo is not active
	$("aside.side-nav a, .header-logo").removeClass("active");
		//make the CTA button large, reposition and all dynamic colour. 
	$(`.scroll-down`).removeClass("CTA-small CTA-hide-mobile");
	if(section){
		//if scrolled past the main carousel make aside nav and main logo
		$(`aside.side-nav a[href='#${section}'], .header-logo`).addClass("active");
		//make the CTA button shrink and move and set colour. 
	$(`.scroll-down`).addClass("CTA-small CTA-hide-mobile");


	}
}

$(() => {
	// Vars
	var menuOverlay =       $(".menu-overlay");
	var menuPanel =         $(".menu-navigation");
	var hamburgerBtn =      $("header .hamburger");
	var resourceListBlock = $(".menu-navigation .resources-list-block");
	var navListBlock =      $(".menu-navigation .nav-list-block");
	var navItemResources =  $(".menu-navigation .nav-item-resources");
	var backToMainMenu =    $(".menu-navigation .resources-list-block .back, .menu-navigation .resources-list-block .back-arrow");

	var isToggled = false;

	//Set initial css
	gsap.set(menuPanel,{x: window.innerWidth, opacity:0});
	gsap.set(resourceListBlock,{x: window.innerWidth});

	// Event Listeners 
	hamburgerBtn.on("click", function() {
			return (isToggled) ? hideMenu() : showMenu();
	});

	navItemResources.on("click", function(e) {
			e.preventDefault;
			showResources();
	});

	backToMainMenu.on("click", function() {
			hideResources();
	});
	gsap.set(".menu-background-overlay", {scaleX: 0})
	// On link click 
	let isAsideScrolling = false;
	$('aside.side-nav a.nav-link, #CTA-button').on("click", function(e) {
		e.preventDefault();
		if(isAsideScrolling){
			return;
		}
		isAsideScrolling = true;
		const tl = gsap.timeline({
		})
		$('body').addClass("is-transitioning");
			$('.news-ticker').hide();
			let anchor = e.target.getAttribute("href") as string;
			let offset = e.target.getAttribute("data-offset");
			let extraScroll = 0;
			if(offset){
				extraScroll = (+offset / 100) * window.innerHeight
			}
			makeAsideSectionActive(anchor.replace("#", ""))
		tl
		.fromTo(".menu-background-overlay", {scaleX: 0, transformOrigin: "left"}, {scaleX: 1, duration: 0.5},)
		.to(window, {duration: 0.1, scrollTo: 0})
		// .to(".side-nav", {opacity: 0, duration: 0.3}, 0)
		.to(window, {
			duration: 0.1,
			scrollTo: { y: anchor, autoKill: true, offsetY: extraScroll, },
		})
		.to(".menu-background-overlay", {scaleX: 0, duration: 0.6, transformOrigin: "right"}, ">0.1")
		// .to(".side-nav", {opacity: 1, duration: 0.3}, "<")
		.call(() => {
			isAsideScrolling = false
			$('body').removeClass("is-transitioning");
			$('.news-ticker').show();
		})

	});
	$('.menu-navigation .nav-list a.nav-link.nav-link-anchor').on("click", function(e) {
		e.preventDefault();
		const tl = gsap.timeline({
			
		})
		let anchor = e.target.getAttribute("href") as string;
			let offset = e.target.getAttribute("data-offset");
			let extraScroll = 0;
			if(offset){
				extraScroll = (+offset / 100) * window.innerHeight
			}
		tl
		.to(window, {duration: 0.1, scrollTo: 0})
		.to(window, {
			duration: 0.05,
			scrollTo: { y: anchor, autoKill: true, offsetY: extraScroll, },
		})
		.call(() => {
			hideMenu()
		})

	});

	$(window).on("resize", function() {

			if((menuPanel.position().left < window.innerWidth) && $(".menu-navigation.hidden").length > 0) {
					gsap.to(menuPanel,{x:"+=" + (window.innerWidth - menuPanel.position().left)});
			}
	});

	// Functions
	function hideMenu() {
			hamburgerBtn.removeClass("change");
			menuPanel.addClass("hidden");
			// if($('.news-ticker').css("visibility") !== "hidden"){
			// 	gsap.to(".news-ticker", {visibility: 1, duration: 0.1})
			// }
			hideResources();

			gsap.to(menuOverlay, {
					// delay:.1,
					duration: .3,
					opacity: "0",
					ease: "none"
			});
			gsap.to(menuPanel, {
					// delay: 0.1,
					x: window.innerWidth,
					duration: .3,
					ease: "none",
			});
			gsap.to(menuPanel, {
					// delay: 0.6,
					opacity: 0
			});

			isToggled = false;
	}   

	function showMenu() {
			hamburgerBtn.addClass("change");
			menuPanel.removeClass("hidden");
			// gsap.to(".news-ticker", {visibility: 'hidden', duration: 0.1})
			gsap.to(menuOverlay,{
					delay:0,
					opacity: "1",
					duration: .3,
					ease: "none"
			});
			gsap.to(menuPanel, {
					opacity: 1,
					duration: 0.4
			});
			gsap.to(menuPanel, {
				 x: 0,
				 duration: .3,
				 ease: "none" 
			});

			isToggled = true;
	}   

	function showResources() {
			gsap.to(navListBlock, {
					opacity: 0,
					x:  -window.innerWidth,
					duration: .3
			});
			gsap.to(resourceListBlock, {
					opacity: 1,
					x:  0,
					duration: .3,
			});
	}

	function hideResources() {
			gsap.to(navListBlock, {
					opacity: 1,
					x: 0,
					duration: .3
			});
			gsap.to(resourceListBlock, {
					opacity: 0,
					x: window.innerWidth,
					duration: .3
			});
	}

});