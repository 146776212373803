import { gsap } from "gsap";
import { displaySelect, onWhiteBackground, RegionMapping } from "..";
import { makeAsideSectionActive } from "../menu";
import $ from "jquery";

const getLeftover = (number) => {
  const mapBounds = (
    document.querySelector(`#why-invest-${number} .map-image`) as any
  ).getBoundingClientRect();
  const percentage = (mapBounds.width / window.innerWidth) * 100;
  const offset = 5;
  const leftOver = 100 - percentage + offset;
  return (leftOver/100);
};
export const forwardDrawLine = [
  { drawSVG: "0" },
  { drawSVG: "100%", duration: 4 },
];
export const reverseDrawLine = [
  { drawSVG: "100% 100%" },
  { drawSVG: "0% 100%", duration: 4 },
];
export const WAViewBox = "893 735 156 213";
export const forwardDrawSVG = [{ drawSVG: "0" }, { drawSVG: "100%" }];
export const reverseDrawSVG = [{ drawSVG: "100% 100%" }, { drawSVG: "0% 100%" }];
export const showPointTimeline = (
  point: string,
  index: number,
  reverse?: boolean
) => {
  const tl = gsap.timeline({
    defaults: {
      duration: 3,
    },
  });
  tl.fromTo(
    `#why-invest-1 .map-image #${point} #lines${
      index > 0 ? "-" + index : ""
    }`,
    { opacity: 0 },
    { opacity: 1, duration: 1 }
  )
    .fromTo(
      `#why-invest-1 .map-image #${point} #lines${
        index > 0 ? "-" + index : ""
      } polyline,#why-invest-1 .map-image #${point} #lines${
        index > 0 ? "-" + index : ""
      } line`,
      reverse ? reverseDrawSVG[0] : forwardDrawSVG[0],
      reverse ? reverseDrawSVG[1] : forwardDrawSVG[1],
      "<"
    )
    .fromTo(
      `#why-invest-1 .map-image #${point} #text${
        index > 0 ? "-" + index : ""
      }`,
      { opacity: 0 },
      { opacity: 1 },
      ">-1"
    );
  return tl;
};

export const showSmallPointTimeline = (parent, point) => {
  const tl = gsap.timeline({
    defaults: {
      duration: 3,
    },
  });
  tl.fromTo(
    `${parent} .map-image #Map-WA-shipping-locations #${point} circle`,
    { opacity: 0 },
    { opacity: 1 }
  )
    .fromTo(
      `${parent} .map-image #Map-WA-shipping-locations #${point} polyline`,
      { drawSVG: "0" },
      { drawSVG: "100%" }
    )
    .fromTo(
      `${parent} .map-image #Map-WA-shipping-locations #${point} g`,
      { opacity: 0 },
      { opacity: 1 },
      ">-2"
    );
  return tl;
};
export const swapText = (oldText, newText, number) => {
  const tl = gsap.timeline({
    defaults: {
      duration: 1,
    },
  });
  tl.to(`#why-invest-${number} .content-text-${oldText}`, {
    opacity: 0,
    translateY: 10,
  })
    .to(
      `#why-invest-${number} .content-text-${newText}`,
      { opacity: 0.1, translateY: -10, duration: 1 },
      "<"
    )
    .to(
      `#why-invest-${number} .content-text-${newText}`,
      { opacity: 1, translateY: 0 },
      ">"
    );
  return tl;
};
export async function whyInvestTimeline() {
  return new Promise<void>((resolve) => {
    let whyInvestTimeline1 = gsap.timeline({
      defaults: {
        duration: 3,
      },
      scrollTrigger: {
        trigger: "#why-invest-1",
        start: "top top",
        pin: true,
        end: "+=2000",
        pinSpacing: true,
        scrub: true,
        invalidateOnRefresh: true,
        // snap: {
        //   snapTo: "labelsDirectional",
        //   delay: 1,
        // },
        onEnterBack: () => {
          makeAsideSectionActive("why-invest-1");
        },
      },
    });
    
    
    const duration = 3;

    //update urls
    Object.keys(RegionMapping).forEach((key) => {
      const region = RegionMapping[key];
      $(`#why-invest-1 #Map-WA-Locations #${key} a`).attr(
        "href",
        `${region.link}`
      );
    });

    $(`#why-invest-1 #Map-WA-Locations a`).on("mouseenter", function(e){
      const $parent = $(this).parent();
      const regionName = $parent.attr("id");
      if(regionName){
        const region = RegionMapping[regionName];
        $parent.find(`[data-name="lines"] .cls-10`).css({ strokeWidth: 0.5 });

      }
    }).on("mouseleave", function(e){
      const $parent = $(this).parent();
      const regionName = $parent.attr("id");
      if(regionName){
        const region = RegionMapping[regionName];
        $parent.find(`[data-name="lines"] .cls-10`).css({ strokeWidth: '' });

      }
    });
    
    
    whyInvestTimeline1

      .set("#why-invest-1 #why-invest-background", {
        scaleX: () => getLeftover(1),
        duration: 0.1,
      })
      .set("#why-invest-1 .why-invest-triangle", {
        top: () => "auto",
        bottom: () => "0",
      })

      //map
      .set("#why-invest-1 .map-image", { opacity: 1 })
      .set("#why-invest-1 .map", { css: { zIndex: 2 } })
      .set("#why-invest-1 .map-image", { attr: { viewBox: WAViewBox } })
      .set("#why-invest-1 .map-image #Map-GMT-Lines", {opacity: 0})

      .to("#why-invest-1 #why-invest-background", { scaleX: () => getLeftover(1), duration: 0.01 })
      .addLabel("why-invest-start")
      .to("#why-invest-1", {}, displaySelect({mobile: 0,default: 7}))
      .call(
        () => {
          makeAsideSectionActive();
        },
        undefined,
        "<"
      )
      .fromTo(
        "#why-invest-1 .content-inner",
        { translateY: "60%", opacity: 0 },
        { translateY: "0%", opacity: 1, duration: 10 },
        "<"
      )
      .fromTo(
        "#why-invest-1 .content-text-1",
        { opacity: 0.2 },
        { opacity: 1, duration: 3 },
        ">-1"
      )
      .call(
        () => {
          makeAsideSectionActive("why-invest-1");
        },
        undefined,
        "<"
      )
      .fromTo(
        "#why-invest-1 .why-invest-triangle",
        { scaleY: 0 },
        { scaleY: () => displaySelect({tabletMobile: 0, default: 1}), transformOrigin: "bottom" },
        "<"
      )
      .fromTo(
        "#why-invest-1 .map-image #Map-WA-Regions",
        { opacity: 0 },
        { opacity: 1, duration: 3 },
        "<"
      )
      .to("#why-invest-1 #why-invest-background", { scaleX: 1, duration: 1 })
      .fromTo(
        "#why-invest-1 .map-image #Map-WA-invert",
        { opacity: 1 },
        { opacity: 0, duration: 1 },
        ">"
      )
      .addLabel("text-start")

      //points
      .add(showPointTimeline("browse", 0), "<-5")
      .addLabel("browse-end")
      .add(showPointTimeline("boodarie", 4, true), "<")
      .addLabel("boodarie-end")
      .add(showPointTimeline("anketell", 5, true), "<")
      .addLabel("anketell-end")
      .add(showPointTimeline("burrup", 2), "<")
      .addLabel("burrup-end")
      .add(showPointTimeline("mitland", 6), "<")
      .addLabel("mitland-end")
      .add(showPointTimeline("ashburton", 3), "<")
      .addLabel("ashburton-end")
      .add(showPointTimeline("oakajee", 12), "<")
      .addLabel("oakajee-end")
      .add(showPointTimeline("kwinana", 7), "<")
      .addLabel("kwinana-end")
      .add(showPointTimeline("rockingham", 13), "<")
      .addLabel("rockingham-end")
      .add(showPointTimeline("kemberton", 8), "<")
      .addLabel("kemberton-end")
      .add(showPointTimeline("shotts", 11, true), "<")
      .addLabel("shotts-end")
      .add(showPointTimeline("mirambeena", 9), "<")
      .addLabel("mirambeena-end")
      .add(showPointTimeline("mungari", 10, true), "<")
      .addLabel("mungari-end")
     
      .to("#why-invest-1 #Map-WA-Locations", { duration: 1 })
   

      // .to("#why-invest-1 #Map-WA-Locations", { opacity: 0, duration: 3 })
      // .to(
      //   "#why-invest-1 #why-invest-background",
      //   {
      //     scaleX: () => displaySelect({ tabletMobile: 0, default: 0.45 }),
      //     duration: 5,
      //     onStart: () => {
      //       onWhiteBackground(true)
      //     },
      //     onComplete: () => {
      //       onWhiteBackground(true)
      //     },
      //     onReverseComplete: () => {
      //       onWhiteBackground(false)
      //     },
      //   },
      //   "<2"
      // );

     
    resolve();
  });
}

export async function whyInvestTimeline2() {
  return new Promise<void>((resolve) => {
    gsap.to("#why-invest-2 .map-image #Map-WA-10days", {
      translateX: 12,
      translateY: 5,
      yoyo: true,
      repeat: -1,
      duration: 3,
      yoyoEase: "none",
      ease: "none",
    });
    gsap.to("#why-invest-2 .map-image #Map-WA-5days", {
      translateX: -35,
      translateY: -20,
      yoyo: true,
      repeat: -1,
      duration: 5,
      yoyoEase: "none",
      ease: "none",
    });

    //carts
    gsap.to("#why-invest-2 .carts-cover #cart-right-wheel-right", {
      y: 3,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });
    gsap.to("#why-invest-2 .carts-cover #cart-right-wheel-left", {
      y: 3,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });
    gsap.to("#why-invest-2 .carts-cover #cart-right-trolley", {
      y: -10,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });
    gsap.to("#why-invest-2 .carts-cover #cart-right-dirt", {
      y: -10,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });
    gsap.to("#why-invest-2 .carts-cover #cart-chain", {
      y: -10,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });
    gsap.to("#why-invest-2 .carts-cover #cart-left-wheel-right", {
      y: 3,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });
    gsap.to("#why-invest-2 .carts-cover #cart-left-wheel-left", {
      y: 3,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });
    gsap.to("#why-invest-2 .carts-cover #cart-left-trolley", {
      y: -10,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });
    gsap.to("#why-invest-2 .carts-cover #cart-left-dirt", {
      y: -10,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });
    let whyInvestTimeline2 = gsap.timeline({
      defaults: {
        duration: 3,
      },
      scrollTrigger: {
        trigger: "#why-invest-2",
        start: "top top",
        pin: true,
        end: "20000", // 13000
        pinSpacing: true,
        scrub: true,
        invalidateOnRefresh: true,
        // snap: {
        //   snapTo: "labelsDirectional",
        //   delay: 1,
        // },
        onEnter: () => {
          makeAsideSectionActive("why-invest-2");
        },
        onEnterBack: () => {
          makeAsideSectionActive("why-invest-2");
        },
      },
    });
    
    const duration = 3;

    
    // gsap.to("#why-invest-2 .map-image #Map-WA-10days", {
    //   translateX: 10,
    //   translateY: 5,
    //   yoyo: true,
    //   repeat: -1,
    //   duration: 3,
    //   yoyoEase: "none",
    //   ease: "none",
    // });
    // gsap.to("#why-invest-2 .map-image #Map-WA-5days", {
    //   translateX: -30,
    //   translateY: 25,
    //   yoyo: true,
    //   repeat: -1,
    //   duration: 5,
    //   yoyoEase: "none",
    //   ease: "none",
    // });

    //carts
    gsap.to("#why-invest-2 .carts-cover #cart-right-wheel-right", {
      y: 3,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });
    gsap.to("#why-invest-2 .carts-cover #cart-right-wheel-left", {
      y: 3,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });
    gsap.to("#why-invest-2 .carts-cover #cart-right-trolley", {
      y: -10,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });
    gsap.to("#why-invest-2 .carts-cover #cart-right-dirt", {
      y: -10,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });
    gsap.to("#why-invest-2 .carts-cover #cart-chain", {
      y: -10,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });
    gsap.to("#why-invest-2 .carts-cover #cart-left-wheel-right", {
      y: 3,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });
    gsap.to("#why-invest-2 .carts-cover #cart-left-wheel-left", {
      y: 3,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });
    gsap.to("#why-invest-2 .carts-cover #cart-left-trolley", {
      y: -10,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });
    gsap.to("#why-invest-2 .carts-cover #cart-left-dirt", {
      y: -10,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });

    
      whyInvestTimeline2
      .set("#why-invest-2 .why-invest-triangle", {
        top: () => "auto",
        bottom: () => "0",
      })
      .set("#why-invest-2 .hat-cover #doughnut-fill path", { drawSVG: "0" })

      //map
      .set("#why-invest-2 .map-image", { opacity: 1 })
      .set("#why-invest-2 .map", { css: { zIndex: 2 } })
      .set("#why-invest-2 .map-image", { attr: { viewBox: WAViewBox } })
      .set("#why-invest-2 #Map-WA-invert", { opacity: 0})
      .set("#why-invest-2 #Map-WA-Locations", { opacity: 0})

      .addLabel("why-invest-start")
      // .to("#why-invest-2", {}, displaySelect({mobile: 0,default: 7}))
      
      .to("#about-sia", { opacity: 0, duration: 0.1 }, "<")
      // .fromTo(
      //   "#why-invest-2 .content-inner",
      //   { translateY: "60%", opacity: 0 },
      //   { translateY: "0%", opacity: 1, duration: 10 },
      //   "<"
      // )
      .fromTo(
        "#why-invest-2 .content-text-2",
        { opacity: 0.2 },
        { opacity: 1, duration: 3 },
        "<"
      )
      .call(
        () => {
          makeAsideSectionActive("why-invest-2");
        },
        undefined,
        "<"
      )
      .fromTo(
        "#why-invest-2 .why-invest-triangle",
        { scaleY: 0 },
        { scaleY: () => displaySelect({tabletMobile: 0, default: 1}), transformOrigin: "bottom" },
        "<"
      )
      .fromTo(
        "#why-invest-2 .map-image #Map-WA-Regions",
        { opacity: 0 },
        { opacity: 1, duration: 3 },
        "<"
      )
      // .to("#why-invest-2 #why-invest-background", { scaleX: 1, duration: 1 })
      // .addLabel("text-start")   

      .fromTo(
        "#why-invest-2 #why-invest-background",
        {
          scaleX: 1
        },
        {
          scaleX: () => displaySelect({ tabletMobile: 0, default: 0.45 }),
          duration: 15,//5
          onStart: () => {
            onWhiteBackground(true)
          },
          onComplete: () => {
            onWhiteBackground(true)
          },
          onReverseComplete: () => {
            onWhiteBackground(false)
          },
        },
        "<"
      )

      // .add(swapText(1, 2, 2), ">-3")
      .addLabel("text-2-start")
      .set("#why-invest-2 .map", { css: { zIndex: "1" } })
      .set("#why-invest-2 #why-invest-background", { css: { zIndex: "1" } },"<")
      .to("#why-invest-2 .map-image", { attr: { viewBox: "0 0 1230 1024" } }, "<")
      .to("#why-invest-2 .map-image #Map-WA-Regions", { opacity: 0 }, "<")
      .addLabel("map-zoomed")

      .fromTo(
        "#why-invest-2 .map-image #Map-Global-Grey",
        { opacity: 0 },
        { opacity: 1 },
        "<"
      )
      // .fromTo("#why-invest .map-image #Map-GMT-Lines", {opacity: 0},{ opacity: 1})
      .fromTo(
        "#why-invest-2 .map-image #Map-GMT-Lines #gmt-line-1",
        forwardDrawLine[0],
        forwardDrawLine[1]
      )
      .fromTo(
        "#why-invest-2 .map-image #Map-GMT-Lines #gmt-line-1-2",
        reverseDrawLine[0],
        reverseDrawLine[1],
        "<"
      )
      .fromTo(
        "#why-invest-2 .map-image #Map-GMT-Lines #gmt-line-2",
        reverseDrawLine[0],
        reverseDrawLine[1],
        "<"
      )
      .fromTo(
        "#why-invest-2 .map-image #Map-GMT-Lines #gmt-line-3",
        forwardDrawLine[0],
        forwardDrawLine[1],
        "<"
      )
      .fromTo(
        "#why-invest-2 .map-image #Map-GMT-Lines #gmt-line-4",
        reverseDrawLine[0],
        reverseDrawLine[1],
        "<"
      )
      .fromTo(
        "#why-invest-2 .map-image #Map-GMT-Lines #gmt-line-5",
        forwardDrawLine[0],
        forwardDrawLine[1],
        "<"
      )
      .fromTo(
        "#why-invest-2 .map-image #Map-GMT-Lines #gmt-line-6",
        forwardDrawLine[0],
        forwardDrawLine[1],
        "<"
      )
      .fromTo(
        "#why-invest-2 .map-image #Map-GMT-Lines #gmt-line-7",
        reverseDrawLine[0],
        reverseDrawLine[1],
        "<"
      )
      .fromTo(
        "#why-invest-2 .map-image #Map-GMT-Lines #gmt-line-8",
        forwardDrawLine[0],
        forwardDrawLine[1],
        "<"
      )
      .fromTo(
        "#why-invest-2 .map-image #MAP-GMT-Times",
        { opacity: 0 },
        { opacity: 1 },
        "<"
      )
      

      // .to("#why-invest", {duration: 3},)

      .fromTo(
        "#why-invest-2 .map-image #Map-Global-Orange",
        { opacity: 0 },
        { opacity: 1 }
      )
      .fromTo(
        "#why-invest-2 .map-image #MAP-GMT-Times #gmt-8",
        { opacity: 0 },
        { opacity: 1 },
        "<"
      )
      .add(swapText(2, 3, 2))
      .addLabel("orange-shown")
      .fromTo(
        "#why-invest-2 .map-image #Map-Global-Yellow",
        { opacity: 0 },
        { opacity: 1 }
      )
      .fromTo(
        "#why-invest-2 .map-image #MAP-GMT-Times #gmt-7",
        { opacity: 0 },
        { opacity: 1 },
        "<"
      )
      .addLabel("yellow-shown")

      //shipping lines
      .fromTo(
        "#why-invest-2 .map-image #Map-WA-Shipping-lines ellipse",
        { opacity: 0 },
        { opacity: 1 }
      )
      .fromTo(
        "#why-invest-2 .map-image #Map-WA-10days",
        { opacity: 0 },
        { opacity: 1 }
      )
      .fromTo(
        "#why-invest-2 .map-image #Map-WA-Shipping-lines circle",
        { opacity: 0 },
        { opacity: 1, duration: 0.25 }
      )
      .fromTo(
        "#why-invest-2 .map-image #Map-WA-5days",
        { opacity: 0 },
        { opacity: 1 },
        ">-0.5"
      )
      .addLabel("world-map-shipping-shown")

      //points

      .add(showSmallPointTimeline("#why-invest-2", "tokyo"))
      .add(showSmallPointTimeline("#why-invest-2", "seoul"), `>-${duration * 2}`)
      .add(showSmallPointTimeline("#why-invest-2", "hongkong"), `>-${duration * 2}`)
      .add(showSmallPointTimeline("#why-invest-2", "beijing"), `>-${duration * 2}`)
      .add(showSmallPointTimeline("#why-invest-2", "shanghai"), `>-${duration * 2}`)
      .add(showSmallPointTimeline("#why-invest-2", "shenzhen"), `>-${duration * 2}`)
      .add(showSmallPointTimeline("#why-invest-2", "dubai"), `>-${duration * 2}`)
      .add(showSmallPointTimeline("#why-invest-2", "singapore"), `>-${duration * 2}`)
      .addLabel("small-pin-singapore-shown")

      .to("#why-invest-2", { duration: 5 }) 

      .to("#why-invest-2 #why-invest-background", {
        scaleX: 1,
        duration: 5,
        onStart: () => {
        },
        onComplete: () => {
          onWhiteBackground(false)
        },
        onReverseComplete: () => {
          onWhiteBackground(true)
        },
      })
      // .to("#why-invest .right-side", {translateY: () => displaySelect({tabletMobile: "100%", default: "0"}), duration: 5}, "<")
      // .to(
      //   "#why-invest-2 .content-inner",
      //   {
      //     translateY: () =>
      //       displaySelect({ tabletMobile: "-20%", default: "0" }),
      //     duration: 5,
      //   },
      //   "<"
      // )
      .to(
        "#why-invest-2 .why-invest-triangle",
        { scaleY: 0, transformOrigin: "bottom" },
        "<"
      )
      .add(swapText(3, 4,2), `>${-duration}`)
      .to(
        "#why-invest-2 .right-side",
        {
          width: () => displaySelect({ tabletMobile: "80%", default: "65%" }),
          right: () => displaySelect({ tabletMobile: "10%", default: 0 }),
          top: () => displaySelect({ tabletMobile: "15%", default: 0 }),
          position: "absolute",
          duration: 0.1,
        },
        ">"
      )

      .set("#why-invest-2 .map", { opacity: 0 }, "<")

      .set("#why-invest-2 .why-invest-triangle", {
        top: () => "0",
        bottom: () => "auto",
      })
      .set(
        "#why-invest-2 .why-invest-triangle path",
        { scaleY: -1, transformOrigin: "center" },
        "<"
      )
      .to("#why-invest-2 .why-invest-triangle", {
        scaleY: 1,
        transformOrigin: "top",
      })

      //carts
      .from(
        "#why-invest-2 .carts-cover #cart-right",
        { translateX: "-50%", translateY: "5%" },
        "<"
      )
      .fromTo(
        "#why-invest-2 .carts-cover #car-road line",
        { drawSVG: "0" },
        { drawSVG: "100%" },
        "<"
      )
      .fromTo(
        "#why-invest-2 .carts-cover #cart-right-trolley",
        { drawSVG: "0" },
        { drawSVG: "100%" },
        ">"
      )
      .fromTo(
        "#why-invest-2 .carts-cover #cart-right-wheel-right",
        { opacity: "0" },
        { opacity: "100%" },
        ">"
      )
      .fromTo(
        "#why-invest-2 .carts-cover #cart-right-wheel-left",
        { opacity: "0" },
        { opacity: "100%" },
        ">"
      )
      .fromTo(
        "#why-invest-2 .carts-cover #cart-right-dirt",
        { opacity: "0" },
        { opacity: "100%" },
        ">"
      )
      .fromTo(
        "#why-invest-2 .carts-cover #cart-chain",
        { opacity: "0" },
        { opacity: "100%" },
        ">"
      )
      .addLabel("cart-shown")

      .to("#why-invest-2 .carts-cover #cart-right", {
        translateX: "150%",
        ease: "none",
        duration: duration * 2,
      })
      .fromTo(
        "#why-invest-2 .carts-cover #car-road line",
        { drawSVG: "0% 100%" },
        { drawSVG: "100% 100%" },
        ">"
      )

      //hats

      .fromTo(
        "#why-invest-2 .hat-cover #doughnut-outline .cls-3:nth-child(1)",
        { drawSVG: "0" },
        { drawSVG: "100%", duration: duration * 3 }
      )
      .fromTo(
        "#why-invest-2 .hat-cover #doughnut-outline .cls-3:nth-child(2)",
        { drawSVG: "0" },
        { drawSVG: "-100%", duration: duration * 3 },
        "<"
      )
      .fromTo(
        "#why-invest-2 .hat-cover #doughnut-hard-hat path",
        { drawSVG: "0" },
        { drawSVG: "100%", duration: duration * 3 },
        `<`
      )
      .add(swapText(4, 5,2), `<${duration}`)
      .fromTo(
        "#why-invest-2 .hat-cover #doughnut-shape",
        { opacity: 0 },
        { opacity: 1 }
      )
      .to("#why-invest-2 .hat-cover #doughnut-fill path", {
        drawSVG: "25%",
        duration: duration * 2,
        ease: "none",
      })
      .addLabel("hat-start")
      .to("#why-invest-2 .hat-cover #doughnut-fill path", {
        drawSVG: "100%",
        duration: duration * 2,
        ease: "none",
      })
      .addLabel("hat-done")
      .to("#why-invest-2 .hat", {})
      .to("#why-invest-2 .hat", { translateY: "-100%", duration: duration * 4 })

      .fromTo(
        "#why-invest-2 .buildings",
        { translateY: "-20%" },
        { translateY: "-100%", duration: duration * 3 },
        "<"
      )
      .add(swapText(5, 6,2), `>${-duration}`)
      .addLabel("buildings-start")

      //buildings
      .fromTo(
        "#why-invest-2 .buildings #factory-1",
        { translateY: "100%" },
        { translateY: "0%" }
      )
      .addLabel("buildings-start-1")
      .fromTo(
        "#why-invest-2 .buildings #factory-2",
        { translateY: "100%" },
        { translateY: "0%" },
        ">-0.1"
      )
      .addLabel("buildings-start-2")
      .fromTo(
        "#why-invest-2 .buildings #factory-3",
        { translateY: "100%" },
        { translateY: "0%" },
        ">-0.1"
      )
      .addLabel("buildings-start-3")
      .fromTo(
        "#why-invest-2 .buildings #factory-4",
        { translateY: "100%" },
        { translateY: "0%" },
        ">-0.1"
      )
      .addLabel("buildings-start-4")
      .fromTo(
        "#why-invest-2 .buildings #factory-5",
        { translateY: "100%" },
        { translateY: "0%" },
        ">-0.1"
      )
      .addLabel("buildings-start-5")
      .to("#why-invest-2 .buildings ", { duration: 6 })

      .add(swapText(6, 7, 2), `>${-duration}`)
      .to("#why-invest-2 .buildings ", { duration: 6 })

      .add(swapText(7, 8, 2), `>${-duration}`)
      .to("#why-invest-2 .buildings ", { duration: 6 })

      .add(swapText(8, 9, 2), `>${-duration}`)
      .to("#why-invest-2 .buildings ", { duration: 6 })

    resolve();
  });
}
