import { gsap } from "gsap";
import $ from "jquery";
import { makeAsideSectionActive } from "../menu";

export async function testimonialsTimeline() {
  return new Promise<void>((resolve) => {
    const randomSign = 1;
    const rotation1 = gsap.utils.random(8, 15, 1) * randomSign;
    const rotation2 = gsap.utils.random(10, 18, 1) * -randomSign;

    let testimonialsTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: "#testimonials",
        start: "center center",
        pin: true,
        end: "+=450%",
        pinSpacing: true,
        scrub: true,
        invalidateOnRefresh: true,
        anticipatePin: 1,
        // snap: {
        // 	snapTo: "labelsDirectional",
        // 	delay: 1
        // },
        onEnter: () => {
          // $("#explore-sia").parent().addClass("explore-sia-pin");
          // makeAsideSectionActive("testimonials");
        },
        onEnterBack: () => {
          // $("#explore-sia").parent().addClass("explore-sia-pin")
          // makeAsideSectionActive("testimonials")
        },
      },
    });
    testimonialsTimeline
      .set("#testimonials .overlay-1", { height: 0, rotate: rotation1 })
      .set("#testimonials .overlay-2", { height: 0, rotate: rotation2 })

      .addLabel("testimonials-start")
      
      .fromTo(
        "#testimonials #testimonials-background",
        { scaleX: 0, transformOrigin: "right" },
        {
          scaleX: 1,
          duration: 40,
          onComplete: () => {
            // makeAsideSectionActive("landscape");
            makeAsideSectionActive("testimonials");
          },
        }
      )
      .call(
        () => {
          makeAsideSectionActive("landscape");
        },
        undefined,
        "<"
      )
      .to("#testimonials #testimonials-background", {
        scaleX: 0,
        duration: 40,
        transformOrigin: "left center",
        onComplete: () => {
          // makeAsideSectionActive("testimonials");
        },
      })
      .fromTo(
        "#testimonials .testimonials-cover",
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 0.01 },
        "<"
      )
      .fromTo(
        "#landscape",
        { autoAlpha: 1 },
        { autoAlpha: 0, duration: 0.01 },
        "<"
      )
      .fromTo(
        "#testimonials .text-1",
        { translateX: "100%" },
        {
          translateX: "-100%",
          duration: 100,
          ease: "none"
        },
        "<-10"
      )
      .fromTo(
        "#testimonials .text-2",
        { translateX: "100%" },
        {
          translateX: "-100%",
          duration: 100,
          ease: "none"
        },
        ">-60"
      )
      .fromTo(
        "#testimonials .text-3",
        { translateX: "100%" },
        {
          translateX: "-100%",
          duration: 100,
          ease: "none"
        },
        ">-60"
      )
      .fromTo(
        "#testimonials .text-4",
        { translateX: "100%" },
        {
          translateX: "-100%",
          duration: 100,
          ease: "none"
        },
        ">-60"
      )
     
      .to("#testimonials .overlay-1", { height: 1100, duration: 60 }, "<20")
      .to("#testimonials .overlay-2", { height: 1100, duration: 60 }, "<")
      .to("#testimonials .overlay-1", {
        height: () => window.innerHeight + 1100,
        rotate: 0,
        duration: 50,
        opacity: 1,
        ease: "power2",
      },">")
      .to(
        "#testimonials .overlay-2",
        {
          height: () => window.innerHeight + 1100,
          rotate: 0,
          duration: 50,
          opacity: 1,
          ease: "power2",
        },
        "<"
      )

      .to("#testimonials .cover-content", { autoAlpha: 0, duration: 30 }, "<")

      .fromTo(
        "#explore-sia, .leader-line",
        { 
          autoAlpha: 0, 
          yPercent: 5
        },
        {
          autoAlpha: 1,
          duration: 10,
          yPercent: 0,
          onUpdate: () => {
            
          },
          onComplete: () => {
            $("#explore-sia").parent().removeClass("explore-sia-pin");
            makeAsideSectionActive("explore-sia");
          },
          onReverseComplete: () => {
            $("#explore-sia").parent().addClass("explore-sia-pin");
            makeAsideSectionActive("testimonials");
          },
        },
        ">-10"
      )

      .addLabel("testimonials-done");
    resolve();
  });
}
