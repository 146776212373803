import { gsap } from "gsap";
import $ from "jquery";
import QRCode from "qrcode";
import Draggable from "gsap/Draggable";
import LeaderLine from "leader-line-new";
import { makeAsideSectionActive } from "../menu";
import { display, displaySelect, RegionMapping } from "..";
export let exploreSIAPath = null as unknown as LeaderLine;

export async function exploreSIATimeline() {
  return new Promise<void>((resolve) => {
    let isActive = false;
    let exploreSIATimeline = gsap.timeline({
      scrollTrigger: {
        trigger: "#explore-sia",
        start: "center center",
        pin: true,
        // pinSpacing: true,
        end: "100%",
        invalidateOnRefresh: true,
        scrub: true,
        onEnter: () => {
          isActive = true;
          // $("#explore-sia").parent().removeClass("explore-sia-pin");
          // makeAsideSectionActive("explore-sia");
        },
        onEnterBack: () => {
          isActive = true;
        },
        onLeave: () => {
          isActive = false;
          // hideCard();
        },
        onLeaveBack: () => {
          isActive = false;
          hideCard();
        },
      },
    });
    gsap.set("#explore-sia .svg-final-map path.cls-3", { drawSVG: "25% 25%" });
    gsap.set("#explore-sia .svg-final-map polyline.cls-3", {
      drawSVG: "100% 100%",
    });
    exploreSIATimeline
      .set("#explore-sia .explore-sia-image > g.cls-1", {
        scale: () => displaySelect({ mobile: 2, tablet: 1.6, default: 1.2 }),
        yPercent: () => displaySelect({ mobile: -30, tablet: -15, default: 0 }),
      })
      .set("#explore-sia .explore-sia-image > g.cls-1", {
        translateX: () =>
          displaySelect({ mobile: "25%", tablet: "45%", default: "55%" }),
      });

    exploreSIATimeline
      .set("#explore-sia .svg-final-map > g:not(#wa-regions) .cls-2", {
        transformOrigin: "center",
      })
      .to(".scroll-down", { opacity: 0, duration: 0.5 }, 0);

    const pins = $(
      "#explore-sia .svg-final-map > g > g:not(#wa-regions):not(#Layer_3) .cls-2"
    );
    const activeClass = "is-svg-region-active";
    const pulseTimeline = gsap.timeline({ repeat: -1, repeatRefresh: true });
    gsap.utils
      .shuffle(
        $(
          "#explore-sia .svg-final-map > g > g:not(#wa-regions):not(#Layer_3) .cls-3"
        ).toArray()
      )
      .forEach(function (item, index) {
        pulseTimeline.to(item, { drawSVG: "-25% 75%" });
        pulseTimeline.to(item, { duration: 2 });
        pulseTimeline.to(item, {
          drawSVG: () => {
            return $(item).siblings(".cls-2").hasClass(activeClass)
              ? "-25% 75%"
              : ("0%" as any);
          },
        });
      });
    

    const hideCard = () => {
      if (exploreSIAPath) {
        exploreSIAPath.hide("draw", { duration: 600 });
        setTimeout(() => {
          exploreSIAPath.remove();
          exploreSIAPath = null as any;
        }, 650);
      }

      const tl = gsap.timeline();
      tl.to(
        "#explore-sia .project-card .project-background",
        { translateX: 40, autoAlpha: 0, transformOrigin: "right" },
        0
      )
        .to(
          "#explore-sia .project-card .project-foreground",
          { translateX: "-10%" },
          0
        )
        .to("#explore-sia .project-card ", { autoAlpha: 0 }, 0)
        .set("#explore-sia .project-card .QR", { autoAlpha: 0 })
        .set("#explore-sia .project-card .project-foreground", {
          translateX: "10%",
        });

      const $existingActive = $(`.${activeClass}`);

      if ($existingActive.length > 0) {
        $existingActive.removeClass(activeClass);
        const $circle = $existingActive.siblings("path.cls-3");
        gsap.to($circle, { drawSVG: "25% 25%" });
        const $path = $existingActive.siblings("polyline.cls-3");
        gsap.to($path, { drawSVG: "100% 100%" });

        $("#explore-sia g.active").removeClass("active");
      }
    };
    let lastKnownScrollPosition = 0;
    let ticking = false;
    function updatePath(scrollPos) {
      if (isActive && exploreSIAPath) {
        exploreSIAPath.position();
        hideCard()
      }
      // Do something with the scroll position
    }
    document.addEventListener("scroll", function (e) {
      lastKnownScrollPosition = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(function () {
          updatePath(lastKnownScrollPosition);
          ticking = false;
        });

        ticking = true;
      }
    });

    const showCard = (item, point) => {
      const start = point;
      const end = $(".project-card .project-foreground")[0];

      const tempPath = new LeaderLine(start, end, {
        path: "grid",
        // startSocket: 'left',
        // endSocket: 'bottom',
        color: "white",
        size: 2,
        startPlug: "behind",
        endPlug: "behind",
        hide: true,
        startSocketGravity: 1,
      });
      if (exploreSIAPath) {
        const oldPath = exploreSIAPath;
        oldPath.hide("draw", {
          duration: displaySelect({ mobile: 1, default: 600 }),
        });
        setTimeout(() => {
          oldPath.remove();
        }, 650);
      }
      tempPath.show("draw", {
        duration: displaySelect({ mobile: 1, default: 600 }),
      });
      exploreSIAPath = tempPath;

      const timeline = gsap.timeline({
        defaults: {
          duration: 0.3,
        },
      });
      const details = RegionMapping[item];
      timeline
        .to(
          "#explore-sia .project-card .project-background",
          { translateX: 40, autoAlpha: 0, transformOrigin: "right" },
          0
        )
        .to(
          "#explore-sia .project-card .project-foreground",
          { translateX: "-10%" },
          0
        )
        .to("#explore-sia .project-card ", { autoAlpha: 0 }, 0)
        .set("#explore-sia .project-card .QR", { autoAlpha: 0 })
        .set("#explore-sia .project-card .project-foreground", {
          translateX: "10%",
        })

        .call(
          () => {
            $("#explore-sia .project-card .project-header").text(
              details.region
            );
            $("#explore-sia .project-card .project-description").text(
              details.name
            );
            $("#explore-sia .project-card .project-header").css({
              background: details.color.dark,
            });
            $("#explore-sia .project-card .project-button").css({
              background: details.color.dark,
            });
            $("#explore-sia .project-card .triangle-1").css({
              borderColor: `transparent ${details.color.light} transparent transparent`,
            });
            $("#explore-sia .project-card .triangle-2").css({
              borderColor: `transparent ${details.color.dark} transparent transparent`,
            });

            $(`#explore-sia #${details.regionId}`).addClass("active");
            QRCode.toDataURL(details.link, {
              margin: 1,
              width: 220,
            }).then((url) => {
              $("#explore-sia .project-card .QR").attr({ src: url });
            });
            $("#explore-sia .project-card .project-image img").attr({
              src: details.image,
            });
            $("#explore-sia .project-card .project-button").attr({
              href: details.link,
            });
          },
          undefined,
          "<"
        )
        // .to("#explore-sia .project-card", {})
        .to("#explore-sia .project-card .project-foreground", {
          translateX: 0,
          duration: 0.3,
        })
        .to(
          "#explore-sia .project-card",
          {
            autoAlpha: 1,
          },
          "<"
        )
        .to(
          "#explore-sia .project-card .QR",
          { autoAlpha: 1, duration: 0.3 },
          "<+0.1"
        )
        .to(
          "#explore-sia .project-card .project-background",
          {
            translateX: 0,
            autoAlpha: 1,
            transformOrigin: "left",
            duration: 0.3,
          },
          ">-0.2"
        );
    };

    pins.on({
      mouseenter: function () {
        const $this = $(this);
        gsap.to($this, { scale: 1.2 });
        const $circle = $this.siblings("path.cls-3");
        const id = $this.parent().attr("id") || -1;

        $(`#explore-sia #${RegionMapping[id].regionId}`).addClass("active");
        gsap.to($circle, { drawSVG: "-25% 75%" });
      },
      mouseleave: function () {
        const $this = $(this);
        const isActive = $this.hasClass(activeClass);
        const $existingActive = $(`.${activeClass}`);
        const id = $this.parent().attr("id") || -1;
        gsap.to($this, { scale: 1 });
        if (!isActive) {
          const $circle = $this.siblings("path.cls-3");
          gsap.to($circle, { drawSVG: "25% 25%" });
        }
        if ($existingActive.length === 0) {
          $(`#explore-sia #${RegionMapping[id].regionId}`).removeClass("active");
        }
      },
      click: function () {
        const $this = $(this);
        const $existingActive = $(`.${activeClass}`);
        const id = $this.parent().attr("id");
        pulseTimeline.invalidate();
        if ($existingActive.length > 0) {
          $existingActive.removeClass(activeClass);
          const $circle = $existingActive.siblings("path.cls-3");
          gsap.to($circle, { drawSVG: "25% 25%" });
          const $path = $existingActive.siblings("polyline.cls-3");
          gsap.to($path, { drawSVG: "100% 100%" });

          $("#explore-sia g.active").removeClass("active");
        }
        $this.addClass(activeClass);
        const $point = $this.siblings("path.cls-3");
        showCard(id, $point[0]);
      },
      touchstart: function () {
        const $this = $(this);
        const $existingActive = $(`.${activeClass}`);
        const id = $this.parent().attr("id");
        pulseTimeline.invalidate();
        if ($existingActive.length > 0) {
          $existingActive.removeClass(activeClass);
          const $circle = $existingActive.siblings("path.cls-3");
          gsap.to($circle, { drawSVG: "25% 25%" });
          const $path = $existingActive.siblings("polyline.cls-3");
          gsap.to($path, { drawSVG: "100% 100%" });

          $("#explore-sia g.active").removeClass("active");
        }
        $this.addClass(activeClass);
        const $point = $this.siblings("path.cls-3");
        showCard(id, $point[0]);
      },
    });
    $("#explore-sia .close-icon").on("click", () => {
      hideCard();
    });

    const { isMobile, isTablet } = display();

    if (!isMobile) {
      Draggable.create(".project-card", {
        bounds: $(".explore-sia-cover"),
        inertia: true,
        allowEventDefault: true,

        onMove: () => {
          if (exploreSIAPath) {
            exploreSIAPath.position();
          }
        },
        onThrowUpdate: () => {
          if (exploreSIAPath) {
            exploreSIAPath.position();
          }
        },
      });

    }
    if(!isMobile){
      Draggable.create("#explore-sia .explore-sia-image > g.cls-1", {
        // bounds: $(".explore-sia-cover"),
        trigger: displaySelect({ default: "#explore-sia .drag-inner" }),
        allowEventDefault: true,
        // inertia: true,
        onMove: () => {
          if (exploreSIAPath) {
            exploreSIAPath.position();
          }
        },
      });

    }


    $( ".zoom-controls .zoom-in" ).on("click",function() {
      $(".svg-final-map").css("transform", "scale(1.8)"); 
    });
    $( ".zoom-controls .zoom-out" ).on("click",function() {
      $(".svg-final-map").css("transform", "scale(1)"); 
    });

    resolve();
  });
}
